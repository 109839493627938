const ChartData = [
  {
    date: 1620086400,
    volumeUSD: 0,
    tvlUSD: 0
  },
  {
    date: 1620172800,
    volumeUSD: 16937182.154839437,
    tvlUSD: 137393087.83917195
  },
  {
    date: 1620259200,
    volumeUSD: 209792599.3253493,
    tvlUSD: 370737139.94136256
  },
  {
    date: 1620345600,
    volumeUSD: 351587666.85455525,
    tvlUSD: 501602157.9472991
  },
  {
    date: 1620432000,
    volumeUSD: 611251973.1162353,
    tvlUSD: 565496000.3094106
  },
  {
    date: 1620518400,
    volumeUSD: 464396482.5397502,
    tvlUSD: 616952743.7559992
  },
  {
    date: 1620604800,
    volumeUSD: 788345423.204635,
    tvlUSD: 575723934.5448283
  },
  {
    date: 1620691200,
    volumeUSD: 628815232.5740358,
    tvlUSD: 741137534.2628868
  },
  {
    date: 1620777600,
    volumeUSD: 1185948395.173485,
    tvlUSD: 816232284.1905187
  },
  {
    date: 1620864000,
    volumeUSD: 1535201935.176622,
    tvlUSD: 934242799.6768714
  },
  {
    date: 1620950400,
    volumeUSD: 820163744.3798683,
    tvlUSD: 991720117.4902359
  },
  {
    date: 1621036800,
    volumeUSD: 834932523.0214449,
    tvlUSD: 979236106.1429205
  },
  {
    date: 1621123200,
    volumeUSD: 1142729897.745995,
    tvlUSD: 996794267.5442643
  },
  {
    date: 1621209600,
    volumeUSD: 1435847105.6000009,
    tvlUSD: 1024307976.7775434
  },
  {
    date: 1621296000,
    volumeUSD: 1208124293.6622465,
    tvlUSD: 1119338013.9955502
  },
  {
    date: 1621382400,
    volumeUSD: 2620631938.82871,
    tvlUSD: 889946495.1808995
  },
  {
    date: 1621468800,
    volumeUSD: 1762314761.1010556,
    tvlUSD: 1087109781.244186
  },
  {
    date: 1621555200,
    volumeUSD: 1597275168.2150087,
    tvlUSD: 989895502.1901212
  },
  {
    date: 1621641600,
    volumeUSD: 1213856356.8901832,
    tvlUSD: 1004276310.3273708
  },
  {
    date: 1621728000,
    volumeUSD: 1897141145.4413369,
    tvlUSD: 950813175.2957507
  },
  {
    date: 1621814400,
    volumeUSD: 1693797125.1385486,
    tvlUSD: 1282841678.111187
  },
  {
    date: 1621900800,
    volumeUSD: 1545955864.849716,
    tvlUSD: 1465561332.1877553
  },
  {
    date: 1621987200,
    volumeUSD: 1462069089.620289,
    tvlUSD: 1606677749.674054
  },
  {
    date: 1622073600,
    volumeUSD: 1172721265.8780608,
    tvlUSD: 1587657885.7579813
  },
  {
    date: 1622160000,
    volumeUSD: 1425241765.0533693,
    tvlUSD: 1563896255.8528657
  },
  {
    date: 1622246400,
    volumeUSD: 1349284812.1677444,
    tvlUSD: 1545421594.3429139
  },
  {
    date: 1622332800,
    volumeUSD: 1164099707.918968,
    tvlUSD: 1637808296.1327977
  },
  {
    date: 1622419200,
    volumeUSD: 1086628151.4485137,
    tvlUSD: 1809197597.7344909
  },
  {
    date: 1622505600,
    volumeUSD: 943454995.8474419,
    tvlUSD: 1807516967.0017955
  },
  {
    date: 1622592000,
    volumeUSD: 749073133.2484515,
    tvlUSD: 1842842475.3700602
  },
  {
    date: 1622678400,
    volumeUSD: 866172290.4273291,
    tvlUSD: 1923061757.628842
  },
  {
    date: 1622764800,
    volumeUSD: 1122238132.9936152,
    tvlUSD: 1861892178.5770445
  },
  {
    date: 1622851200,
    volumeUSD: 798005345.9767246,
    tvlUSD: 1819259746.7130854
  },
  {
    date: 1622937600,
    volumeUSD: 478092901.4179029,
    tvlUSD: 1872774246.3009293
  },
  {
    date: 1623024000,
    volumeUSD: 879634336.4701853,
    tvlUSD: 1720995908.0472813
  },
  {
    date: 1623110400,
    volumeUSD: 1348066135.2426925,
    tvlUSD: 1760032043.715366
  },
  {
    date: 1623196800,
    volumeUSD: 1222463482.5414572,
    tvlUSD: 1825880807.2891881
  },
  {
    date: 1623283200,
    volumeUSD: 879616104.5110492,
    tvlUSD: 1787650380.667006
  },
  {
    date: 1623369600,
    volumeUSD: 846571768.267806,
    tvlUSD: 1704982799.3073895
  },
  {
    date: 1623456000,
    volumeUSD: 783476587.6008517,
    tvlUSD: 1670697139.0298846
  },
  {
    date: 1623542400,
    volumeUSD: 809576745.8563164,
    tvlUSD: 1796127198.5802255
  },
  {
    date: 1623628800,
    volumeUSD: 823253440.3606173,
    tvlUSD: 1823108544.3471057
  },
  {
    date: 1623715200,
    volumeUSD: 817964952.9804674,
    tvlUSD: 1825445049.4470713
  },
  {
    date: 1623801600,
    volumeUSD: 758842386.5197484,
    tvlUSD: 1750226668.508247
  },
  {
    date: 1623888000,
    volumeUSD: 713235923.9448757,
    tvlUSD: 1744966736.301019
  },
  {
    date: 1623974400,
    volumeUSD: 896280914.4742075,
    tvlUSD: 1695560490.214725
  },
  {
    date: 1624060800,
    volumeUSD: 652931012.792974,
    tvlUSD: 1677689544.0104487
  },
  {
    date: 1624147200,
    volumeUSD: 877740149.3487825,
    tvlUSD: 1721514399.9915087
  },
  {
    date: 1624233600,
    volumeUSD: 1643525845.1115973,
    tvlUSD: 1484200602.5804467
  },
  {
    date: 1624320000,
    volumeUSD: 1357065680.457716,
    tvlUSD: 1495147229.3724232
  },
  {
    date: 1624406400,
    volumeUSD: 940093150.3373804,
    tvlUSD: 1574087895.7863202
  },
  {
    date: 1624492800,
    volumeUSD: 763487843.4614283,
    tvlUSD: 1538356671.15726
  },
  {
    date: 1624579200,
    volumeUSD: 922375556.0349401,
    tvlUSD: 1471766843.5912318
  },
  {
    date: 1624665600,
    volumeUSD: 865726815.6055808,
    tvlUSD: 1446109834.9920714
  },
  {
    date: 1624752000,
    volumeUSD: 823527056.6783988,
    tvlUSD: 1554608023.9426055
  },
  {
    date: 1624838400,
    volumeUSD: 1054153704.1673356,
    tvlUSD: 1594745358.1788986
  },
  {
    date: 1624924800,
    volumeUSD: 913362896.0277952,
    tvlUSD: 1674296047.5335965
  },
  {
    date: 1625011200,
    volumeUSD: 1010505942.1555052,
    tvlUSD: 1708111357.259485
  },
  {
    date: 1625097600,
    volumeUSD: 924741998.9719634,
    tvlUSD: 1666932708.9303753
  },
  {
    date: 1625184000,
    volumeUSD: 690560819.9981803,
    tvlUSD: 1706779908.2921305
  },
  {
    date: 1625270400,
    volumeUSD: 612187862.1637814,
    tvlUSD: 1728196359.3357565
  },
  {
    date: 1625356800,
    volumeUSD: 703515349.3279132,
    tvlUSD: 1761037779.6279314
  },
  {
    date: 1625443200,
    volumeUSD: 849551649.6514072,
    tvlUSD: 1710014357.319376
  },
  {
    date: 1625529600,
    volumeUSD: 1063693253.3941365,
    tvlUSD: 1767505630.0515962
  },
  {
    date: 1625616000,
    volumeUSD: 766487322.3844619,
    tvlUSD: 1747250187.029352
  },
  {
    date: 1625702400,
    volumeUSD: 968820031.07289,
    tvlUSD: 1672613687.8457613
  },
  {
    date: 1625788800,
    volumeUSD: 798924664.3725308,
    tvlUSD: 1698857423.4434385
  },
  {
    date: 1625875200,
    volumeUSD: 759047181.1880293,
    tvlUSD: 1691500559.8575141
  },
  {
    date: 1625961600,
    volumeUSD: 447451391.29727906,
    tvlUSD: 1708502619.7596526
  },
  {
    date: 1626048000,
    volumeUSD: 622820858.8745154,
    tvlUSD: 1665942501.73509
  },
  {
    date: 1626134400,
    volumeUSD: 881274866.0090593,
    tvlUSD: 1629049533.8301325
  },
  {
    date: 1626220800,
    volumeUSD: 987428048.149119,
    tvlUSD: 1629480672.758651
  },
  {
    date: 1626307200,
    volumeUSD: 969232631.2013743,
    tvlUSD: 1600395955.2929873
  },
  {
    date: 1626393600,
    volumeUSD: 734441035.5532194,
    tvlUSD: 1615480003.9328926
  },
  {
    date: 1626480000,
    volumeUSD: 636921813.766785,
    tvlUSD: 1638608331.2224774
  },
  {
    date: 1626566400,
    volumeUSD: 652400447.5922412,
    tvlUSD: 1643137264.3777635
  },
  {
    date: 1626652800,
    volumeUSD: 635029274.5382221,
    tvlUSD: 1595901219.4664469
  },
  {
    date: 1626739200,
    volumeUSD: 845536136.2197757,
    tvlUSD: 1564039792.949434
  },
  {
    date: 1626825600,
    volumeUSD: 1127174816.2903163,
    tvlUSD: 1687535976.951019
  },
  {
    date: 1626912000,
    volumeUSD: 915603973.5899873,
    tvlUSD: 1732731443.058676
  },
  {
    date: 1626998400,
    volumeUSD: 747662479.5422829,
    tvlUSD: 1805191986.3061135
  },
  {
    date: 1627084800,
    volumeUSD: 715038866.385205,
    tvlUSD: 1763845106.9157994
  },
  {
    date: 1627171200,
    volumeUSD: 623545004.662714,
    tvlUSD: 1772074306.8136382
  },
  {
    date: 1627257600,
    volumeUSD: 1953222068.983361,
    tvlUSD: 1817246997.1095424
  },
  {
    date: 1627344000,
    volumeUSD: 1276923887.5030296,
    tvlUSD: 1854889319.557643
  },
  {
    date: 1627430400,
    volumeUSD: 1226191983.0638719,
    tvlUSD: 1897291447.24755
  },
  {
    date: 1627516800,
    volumeUSD: 777612090.7470958,
    tvlUSD: 1944165400.82528
  },
  {
    date: 1627603200,
    volumeUSD: 1064389728.092197,
    tvlUSD: 1984159089.3961449
  },
  {
    date: 1627689600,
    volumeUSD: 737560577.5320761,
    tvlUSD: 1988772254.6227229
  },
  {
    date: 1627776000,
    volumeUSD: 1060017269.598849,
    tvlUSD: 1963474508.7459443
  },
  {
    date: 1627862400,
    volumeUSD: 1084713586.7470255,
    tvlUSD: 2089837086.8196528
  },
  {
    date: 1627948800,
    volumeUSD: 1339617281.4336782,
    tvlUSD: 2047221188.5469198
  },
  {
    date: 1628035200,
    volumeUSD: 1522565099.9186234,
    tvlUSD: 2161609360.249058
  },
  {
    date: 1628121600,
    volumeUSD: 1672804767.7403262,
    tvlUSD: 2167029571.4058847
  },
  {
    date: 1628208000,
    volumeUSD: 1352187905.3351843,
    tvlUSD: 2285512924.046703
  },
  {
    date: 1628294400,
    volumeUSD: 1799607570.3701527,
    tvlUSD: 2396832402.7574177
  },
  {
    date: 1628380800,
    volumeUSD: 1540454447.5473142,
    tvlUSD: 2368448289.8930807
  },
  {
    date: 1628467200,
    volumeUSD: 2153316158.4062314,
    tvlUSD: 2322327257.9259143
  },
  {
    date: 1628553600,
    volumeUSD: 1416214344.4288857,
    tvlUSD: 2321948342.6695666
  },
  {
    date: 1628640000,
    volumeUSD: 1198606100.8874905,
    tvlUSD: 2365094775.9960613
  },
  {
    date: 1628726400,
    volumeUSD: 1469815976.7028422,
    tvlUSD: 2332683229.397194
  },
  {
    date: 1628812800,
    volumeUSD: 1059076704.1290656,
    tvlUSD: 2453012046.2743387
  },
  {
    date: 1628899200,
    volumeUSD: 801451005.1675252,
    tvlUSD: 2566870281.8130426
  },
  {
    date: 1628985600,
    volumeUSD: 1072489835.2828766,
    tvlUSD: 2601822137.4999995
  },
  {
    date: 1629072000,
    volumeUSD: 1279179213.0472496,
    tvlUSD: 2537327846.451189
  },
  {
    date: 1629158400,
    volumeUSD: 1902576658.2670228,
    tvlUSD: 2483283250.8137956
  },
  {
    date: 1629244800,
    volumeUSD: 1645149260.9978962,
    tvlUSD: 2504878084.901261
  },
  {
    date: 1629331200,
    volumeUSD: 1269223156.9857864,
    tvlUSD: 2639723072.3246484
  },
  {
    date: 1629417600,
    volumeUSD: 1199528398.8997755,
    tvlUSD: 2800909989.624517
  },
  {
    date: 1629504000,
    volumeUSD: 895169750.881085,
    tvlUSD: 2834790033.649684
  },
  {
    date: 1629590400,
    volumeUSD: 1016103571.8132173,
    tvlUSD: 2885788143.84026
  },
  {
    date: 1629676800,
    volumeUSD: 1243729911.9409423,
    tvlUSD: 2921482699.0575757
  },
  {
    date: 1629763200,
    volumeUSD: 1487850232.891857,
    tvlUSD: 2845914713.90038
  },
  {
    date: 1629849600,
    volumeUSD: 1261043362.9604337,
    tvlUSD: 2903593227.683633
  },
  {
    date: 1629936000,
    volumeUSD: 1214691778.7448444,
    tvlUSD: 2785762972.931844
  },
  {
    date: 1630022400,
    volumeUSD: 1040992896.6151212,
    tvlUSD: 2891912282.2012396
  },
  {
    date: 1630108800,
    volumeUSD: 589170827.354201,
    tvlUSD: 2882863317.4269156
  },
  {
    date: 1630195200,
    volumeUSD: 980680095.3035457,
    tvlUSD: 2920316202.6972747
  },
  {
    date: 1630281600,
    volumeUSD: 1280117228.9501152,
    tvlUSD: 2896346734.66599
  },
  {
    date: 1630368000,
    volumeUSD: 1638326253.656685,
    tvlUSD: 2992527456.2357125
  },
  {
    date: 1630454400,
    volumeUSD: 1760454205.2841337,
    tvlUSD: 2968184420.3296328
  },
  {
    date: 1630540800,
    volumeUSD: 1420976943.8290248,
    tvlUSD: 2917530444.2795014
  },
  {
    date: 1630627200,
    volumeUSD: 1632626566.1919694,
    tvlUSD: 2918565517.783722
  },
  {
    date: 1630713600,
    volumeUSD: 1177458837.6785803,
    tvlUSD: 2936862501.019205
  },
  {
    date: 1630800000,
    volumeUSD: 1152404343.2994177,
    tvlUSD: 2972714168.280165
  },
  {
    date: 1630886400,
    volumeUSD: 1240586106.4249504,
    tvlUSD: 2944095555.413996
  },
  {
    date: 1630972800,
    volumeUSD: 2523769310.307768,
    tvlUSD: 2493704800.665514
  },
  {
    date: 1631059200,
    volumeUSD: 2091653235.9213185,
    tvlUSD: 2519192649.739326
  },
  {
    date: 1631145600,
    volumeUSD: 1377980968.9256897,
    tvlUSD: 2536719439.3177557
  },
  {
    date: 1631232000,
    volumeUSD: 1438115325.6140451,
    tvlUSD: 2416226395.8922806
  },
  {
    date: 1631318400,
    volumeUSD: 958291591.02164,
    tvlUSD: 2451439539.9759803
  },
  {
    date: 1631404800,
    volumeUSD: 882429682.031644,
    tvlUSD: 2510604947.6001706
  },
  {
    date: 1631491200,
    volumeUSD: 1287125512.6412919,
    tvlUSD: 2448874843.806233
  },
  {
    date: 1631577600,
    volumeUSD: 861101954.7234758,
    tvlUSD: 2539255788.114048
  },
  {
    date: 1631664000,
    volumeUSD: 895084108.129234,
    tvlUSD: 2621584157.552642
  },
  {
    date: 1631750400,
    volumeUSD: 1238048641.7822592,
    tvlUSD: 2632677435.066124
  },
  {
    date: 1631836800,
    volumeUSD: 1061441186.4318829,
    tvlUSD: 2614211715.9738894
  },
  {
    date: 1631923200,
    volumeUSD: 753440297.2665044,
    tvlUSD: 2643350218.4447227
  },
  {
    date: 1632009600,
    volumeUSD: 773027835.6371346,
    tvlUSD: 2658472513.0143533
  },
  {
    date: 1632096000,
    volumeUSD: 2215707639.3060617,
    tvlUSD: 2435259908.4999185
  },
  {
    date: 1632182400,
    volumeUSD: 2110685675.9097207,
    tvlUSD: 2344627238.454454
  },
  {
    date: 1632268800,
    volumeUSD: 1401511425.7773178,
    tvlUSD: 2551069118.7034698
  },
  {
    date: 1632355200,
    volumeUSD: 1063136041.1674227,
    tvlUSD: 2612192246.536259
  },
  {
    date: 1632441600,
    volumeUSD: 1530095942.3693953,
    tvlUSD: 2517142497.7135715
  },
  {
    date: 1632528000,
    volumeUSD: 1129382608.5821726,
    tvlUSD: 2544375529.6553483
  },
  {
    date: 1632614400,
    volumeUSD: 1538097281.7659001,
    tvlUSD: 2606528903.1576295
  },
  {
    date: 1632700800,
    volumeUSD: 1222740739.3528159,
    tvlUSD: 2569522399.198025
  },
  {
    date: 1632787200,
    volumeUSD: 1157990175.5262856,
    tvlUSD: 2490888814.3079286
  },
  {
    date: 1632873600,
    volumeUSD: 974421942.2531954,
    tvlUSD: 2525604398.285182
  },
  {
    date: 1632960000,
    volumeUSD: 1195995155.641896,
    tvlUSD: 2607891111.3781176
  },
  {
    date: 1633046400,
    volumeUSD: 1325668411.3076417,
    tvlUSD: 2763329698.663363
  },
  {
    date: 1633132800,
    volumeUSD: 946526520.2105149,
    tvlUSD: 2800389402.0720544
  },
  {
    date: 1633219200,
    volumeUSD: 955672124.8287847,
    tvlUSD: 2813218612.8853183
  },
  {
    date: 1633305600,
    volumeUSD: 1218958320.4331675,
    tvlUSD: 2791932341.9023485
  },
  {
    date: 1633392000,
    volumeUSD: 1026660030.9549081,
    tvlUSD: 2871230944.310563
  },
  {
    date: 1633478400,
    volumeUSD: 1497752701.0949445,
    tvlUSD: 2901912638.0398345
  },
  {
    date: 1633564800,
    volumeUSD: 1338465952.0371585,
    tvlUSD: 2905252119.717251
  },
  {
    date: 1633651200,
    volumeUSD: 1105890644.5339613,
    tvlUSD: 2952821936.34761
  },
  {
    date: 1633737600,
    volumeUSD: 740609774.441882,
    tvlUSD: 2959327240.2685876
  },
  {
    date: 1633824000,
    volumeUSD: 1117405381.1648777,
    tvlUSD: 2889276795.441877
  },
  {
    date: 1633910400,
    volumeUSD: 1481091965.2029004,
    tvlUSD: 2933244450.8237925
  },
  {
    date: 1633996800,
    volumeUSD: 1492403552.174978,
    tvlUSD: 2912913830.4732985
  },
  {
    date: 1634083200,
    volumeUSD: 1297703607.1563752,
    tvlUSD: 2968865605.598989
  },
  {
    date: 1634169600,
    volumeUSD: 1345938762.6865952,
    tvlUSD: 3034520062.3258095
  },
  {
    date: 1634256000,
    volumeUSD: 1887808809.9657533,
    tvlUSD: 3130322112.758637
  },
  {
    date: 1634342400,
    volumeUSD: 1348592165.6286795,
    tvlUSD: 3134902918.5552115
  },
  {
    date: 1634428800,
    volumeUSD: 1188092243.4995973,
    tvlUSD: 3213752192.5133567
  },
  {
    date: 1634515200,
    volumeUSD: 1391542227.8557496,
    tvlUSD: 3186024301.878003
  },
  {
    date: 1634601600,
    volumeUSD: 1470894732.32955,
    tvlUSD: 3288743770.6716905
  },
  {
    date: 1634688000,
    volumeUSD: 1642662941.241914,
    tvlUSD: 3458279883.5563517
  },
  {
    date: 1634774400,
    volumeUSD: 2607839791.2457314,
    tvlUSD: 3429478998.125668
  },
  {
    date: 1634860800,
    volumeUSD: 1718241726.2791119,
    tvlUSD: 3368506493.5660996
  },
  {
    date: 1634947200,
    volumeUSD: 1292078559.6135552,
    tvlUSD: 3458293772.409474
  },
  {
    date: 1635033600,
    volumeUSD: 1209079558.0759904,
    tvlUSD: 3443447735.2260213
  },
  {
    date: 1635120000,
    volumeUSD: 1213817513.2820237,
    tvlUSD: 3543817379.412662
  },
  {
    date: 1635206400,
    volumeUSD: 1785302660.836401,
    tvlUSD: 3467016574.827309
  },
  {
    date: 1635292800,
    volumeUSD: 2501445810.8719153,
    tvlUSD: 3416568103.4921775
  },
  {
    date: 1635379200,
    volumeUSD: 2247458561.866114,
    tvlUSD: 3557183989.4912853
  },
  {
    date: 1635465600,
    volumeUSD: 1659133551.550807,
    tvlUSD: 3618222333.786843
  },
  {
    date: 1635552000,
    volumeUSD: 1206455863.466155,
    tvlUSD: 3613486215.02399
  },
  {
    date: 1635638400,
    volumeUSD: 1278068908.3547614,
    tvlUSD: 3623348782.3492694
  },
  {
    date: 1635724800,
    volumeUSD: 1333385119.1846173,
    tvlUSD: 3654954035.4839463
  },
  {
    date: 1635811200,
    volumeUSD: 1651353582.5628278,
    tvlUSD: 3809415121.664829
  },
  {
    date: 1635897600,
    volumeUSD: 1729024423.3918543,
    tvlUSD: 3841438101.6050005
  },
  {
    date: 1635984000,
    volumeUSD: 1553661563.9216814,
    tvlUSD: 3743089535.3852158
  },
  {
    date: 1636070400,
    volumeUSD: 1155186843.7450407,
    tvlUSD: 3742642952.49793
  },
  {
    date: 1636156800,
    volumeUSD: 1176935133.3181899,
    tvlUSD: 3815999117.375169
  },
  {
    date: 1636243200,
    volumeUSD: 1088532554.69722,
    tvlUSD: 3939288593.769207
  },
  {
    date: 1636329600,
    volumeUSD: 1672402780.5292702,
    tvlUSD: 4064323912.9027014
  },
  {
    date: 1636416000,
    volumeUSD: 1749877746.9257574,
    tvlUSD: 4040147770.8347936
  },
  {
    date: 1636502400,
    volumeUSD: 3150260765.4086165,
    tvlUSD: 4010961069.9550543
  },
  {
    date: 1636588800,
    volumeUSD: 1868944836.7998085,
    tvlUSD: 4088390771.039356
  },
  {
    date: 1636675200,
    volumeUSD: 2161727965.706404,
    tvlUSD: 4078501192.388717
  },
  {
    date: 1636761600,
    volumeUSD: 1136818064.423595,
    tvlUSD: 4086231335.779262
  },
  {
    date: 1636848000,
    volumeUSD: 1297631617.2442043,
    tvlUSD: 4097214589.782933
  },
  {
    date: 1636934400,
    volumeUSD: 1690920447.042614,
    tvlUSD: 4061291780.7663474
  },
  {
    date: 1637020800,
    volumeUSD: 3004153138.9360747,
    tvlUSD: 3894202902.605078
  },
  {
    date: 1637107200,
    volumeUSD: 2207599373.3198433,
    tvlUSD: 4064215748.6046553
  },
  {
    date: 1637193600,
    volumeUSD: 2805508416.858585,
    tvlUSD: 3871459676.400405
  },
  {
    date: 1637280000,
    volumeUSD: 2022192860.3362234,
    tvlUSD: 4138681589.9577255
  },
  {
    date: 1637366400,
    volumeUSD: 1541029298.3645232,
    tvlUSD: 4246679817.951385
  },
  {
    date: 1637452800,
    volumeUSD: 1587762304.362027,
    tvlUSD: 4164246065.496851
  },
  {
    date: 1637539200,
    volumeUSD: 2577115919.5736823,
    tvlUSD: 4074437488.6873226
  },
  {
    date: 1637625600,
    volumeUSD: 2269169664.260983,
    tvlUSD: 4238672855.6205664
  },
  {
    date: 1637712000,
    volumeUSD: 2257599357.8866067,
    tvlUSD: 4218180529.876056
  },
  {
    date: 1637798400,
    volumeUSD: 2119292244.557552,
    tvlUSD: 4376764127.758102
  },
  {
    date: 1637884800,
    volumeUSD: 3633589528.080929,
    tvlUSD: 3950207313.625054
  },
  {
    date: 1637971200,
    volumeUSD: 1720266530.8805456,
    tvlUSD: 4079950894.2912283
  },
  {
    date: 1638057600,
    volumeUSD: 2064856124.7092288,
    tvlUSD: 4126223717.1612296
  },
  {
    date: 1638144000,
    volumeUSD: 1998509349.1339395,
    tvlUSD: 4298851444.855556
  },
  {
    date: 1638230400,
    volumeUSD: 3403880067.9245358,
    tvlUSD: 4298883111.356393
  },
  {
    date: 1638316800,
    volumeUSD: 1225632697.8732357,
    tvlUSD: 4339073606.4514675
  }
]

export default ChartData
