import React from 'react'
import styled from 'styled-components'

import { useActiveWeb3React } from '../../hooks'
import { CardNoise } from '../earn/styled'
import { TYPE } from '../../theme'
import Settings from '../Settings'

import Web3Status from '../Web3Status'
import ClaimModal from '../claim/ClaimModal'
import { useToggleSelfClaimModal, useShowClaimPopup } from '../../state/application/hooks'
import { useUserHasAvailableClaim } from '../../state/claim/hooks'
import { useUserHasSubmittedClaim } from '../../state/transactions/hooks'
import { Dots } from '../swap/styleds'
import WalletBalance from '../AccountDetails/wallets'
import MenuButton from '../../assets/svg/menu/menu-button.svg'

const HeaderFrame = styled.div`
  display: flex;
  grid-template-columns: 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  padding: 1rem;
  z-index: 3;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    padding: 1rem;
    width: calc(100%);
    position: relative;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 1rem;
  `}
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    max-width: 960px;
    padding: 1rem;
    z-index: 99;
    height: 72px;
    border-radius: 0
    justify-content: center;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};
`

const MenuElementWrap = styled.div`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: inherit;
  `};
`

const SubMenuElementWrap = styled.div`
  align-items: center;
  display: flex;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: block;
  `};
  & > * {
    display: inline-block;
  }
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => theme.bg14};
  border-radius: 8px;
  white-space: nowrap;
  width: 100%;
  padding: 4px 8px;
  height: 36px;
  margin-right: 5px;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-right: 0px;
    font-size: 11px;
    height: 25px;

    display: none;
  `};
`

const UNIAmount = styled(AccountElement)`
  color: white;
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.bg3};
`

const UNIWrapper = styled.span`
  width: fit-content;
  position: relative;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.9;
  }
`

const WalletElement = styled.div`
  display: flex;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
`

interface HeaderProps {
  handleMenuVisibility: Function
}

function Header({ handleMenuVisibility }: HeaderProps) {
  const { account } = useActiveWeb3React()
  const toggleClaimModal = useToggleSelfClaimModal()
  const availableClaim: boolean = useUserHasAvailableClaim(account)
  const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)
  const showClaimPopup = useShowClaimPopup()

  return (
    <HeaderFrame>
      <ClaimModal />
      <SubMenuElementWrap>
        <img onClick={() => handleMenuVisibility(true)} src={MenuButton} alt="Menu button" />
      </SubMenuElementWrap>
      <HeaderControls>
        <HeaderElement>
          {availableClaim && !showClaimPopup && (
            <UNIWrapper onClick={toggleClaimModal}>
              <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                <TYPE.white padding="0 2px">
                  {claimTxn && !claimTxn?.receipt ? <Dots>Claiming SWAPP</Dots> : 'Claim SWAPP'}
                </TYPE.white>
              </UNIAmount>
              <CardNoise />
            </UNIWrapper>
          )}
          <WalletElement>
            <WalletBalance />
          </WalletElement>
          <Web3Status />
          <MenuElementWrap>
            <Settings />
          </MenuElementWrap>
        </HeaderElement>
      </HeaderControls>
    </HeaderFrame>
  )
}

export default Header
