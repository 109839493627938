import React, { useEffect, useState } from 'react'
import Loader from '../../components/Loader'
import styled from 'styled-components'

const StyledLoader = styled(Loader)`
  display: flex;
  align-items: center;
  min-height: 100vh;
`

export default function AnyswapBridge() {
  const [visibility, setVisibility] = useState<boolean>(false)

  useEffect(() => {
    const iframe = document.getElementById('bridge')
    if (iframe) {
      iframe?.addEventListener('load', function() {
        setVisibility(true)
      })
    }
  }, [])

  return (
    <>
      {!visibility && <StyledLoader size="96px" />}
      <iframe
        style={{ border: 'none' }}
        width="100%"
        id="bridge"
        height="100%"
        src="https://anyswap.exchange/#/router"
        title="Embed page"
      ></iframe>
    </>
  )
}
