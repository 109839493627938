// the Uniswap Default token list lives here
export const DEFAULT_TOKEN_LIST_URL = 'tokens.uniswap.eth'

export const DEFAULT_LIST_OF_LISTS: string[] = [
  // DEFAULT_TOKEN_LIST_URL,
  // 't2crtokens.eth', // kleros
  // 'tokens.1inch.eth', // 1inch
  // 'synths.snx.eth',
  // 'tokenlist.dharma.eth',
  // 'defi.cmc.eth',
  // 'erc20.cmc.eth',
  // 'stablecoin.cmc.eth',
  // 'tokenlist.zerion.eth',
  // 'tokenlist.aave.eth',
  // 'https://www.coingecko.com/tokens_list/uniswap/defi_100/v_0_0_0.json',
  // 'https://app.tryroll.com/tokens.json',
  // 'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json',
  // 'https://defiprime.com/defiprime.tokenlist.json',
  // 'https://umaproject.org/uma.tokenlist.json'
]

export const DEFAULT_HARDCODED_TOKEN_LIST = {
  name: 'ERC20 Token List',
  timestamp: '2021-13-11T10:14:25.408Z',
  version: {
    major: 1,
    minor: 1,
    patch: 1
  },
  tags: {},
  logoURI: '',
  keywords: ['Swapp', 'default'],
  tokens: [
    {
      name: 'SWAPP token',
      address: '0x245a551ee0F55005e510B239c917fA34b41B3461',
      symbol: 'SWAPP',
      decimals: 18,
      chainId: 25,
      logoURI: ''
    },
    {
      name: 'Hibiki.finance',
      address: '0x6B66fCB66Dba37F99876a15303b759c73fc54ed0',
      symbol: 'cHIBIKI',
      decimals: 18,
      chainId: 25,
      logoURI: ''
    },
    {
      name: 'Wrapped CRO',
      address: '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
      symbol: 'WCRO',
      decimals: 18,
      chainId: 25,
      logoURI: ''
    },
    {
      name: 'Wrapped ETH',
      address: '0xe44Fd7fCb2b1581822D0c862B68222998a0c299a',
      symbol: 'WETH',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://cryptologos.cc/logos/ethereum-eth-logo.png?v=014'
    },
    {
      name: 'Wrapped BTC',
      address: '0x062E66477Faf219F25D27dCED647BF57C3107d52',
      symbol: 'WBTC',
      decimals: 8,
      chainId: 25,
      logoURI: 'https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=014'
    },
    {
      name: 'USDC',
      address: '0xc21223249CA28397B4B6541dfFaEcC539BfF0c59',
      symbol: 'USDC',
      decimals: 6,
      chainId: 25,
      logoURI: 'https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=014'
    },
    {
      name: 'USDT',
      address: '0x66e428c3f67a68878562e79A0234c1F83c208770',
      symbol: 'USDT',
      decimals: 6,
      chainId: 25,
      logoURI: 'https://cryptologos.cc/logos/tether-usdt-logo.png?v=014'
    },
    {
      name: 'DAI',
      address: '0xF2001B145b43032AAF5Ee2884e456CCd805F677D',
      symbol: 'DAI',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://cryptologos.cc/logos/multi-collateral-dai-dai-logo.png?v=014'
    },
    {
      name: 'AVAX',
      address: '0x765277EebeCA2e31912C9946eAe1021199B39C61',
      symbol: 'AVAX',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png'
    },
    {
      name: 'BNB',
      address: '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f',
      symbol: 'BNB',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://cryptologos.cc/logos/binance-coin-bnb-logo.png?v=014'
    },
    {
      name: 'Fantom',
      address: '0xB44a9B6905aF7c801311e8F4E76932ee959c663C',
      symbol: 'FTM',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://cryptologos.cc/logos/fantom-ftm-logo.png?v=014'
    },
    {
      name: 'MATIC',
      address: '0xc9BAA8cfdDe8E328787E29b4B078abf2DaDc2055',
      symbol: 'MATIC',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png'
    },
    {
      name: 'MIM',
      address: '0x2BC472832Eb20C65F82d6A869db845aB0C0099ba',
      symbol: 'MIM',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://assets.coingecko.com/coins/images/16786/small/mimlogopng.png'
    },
    {
      name: 'BUSD',
      address: '0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8',
      symbol: 'BUSD',
      decimals: 18,
      chainId: 25,
      logoURI: 'https://cryptologos.cc/logos/binance-usd-busd-logo.png?v=014'
    },
    {
      name: 'CrO cRo',
      address: '0x17C00923Ce44f2748DB6A14f979D55C497A267e4',
      symbol: 'CROCRO',
      decimals: 18,
      chainId: 25,
      logoURI: ''
    },
    {
      name: 'CronosPunkYield',
      address: '0x2302d9e742eDF1f6F3547eB62AbBB76Eb5c073c7',
      symbol: 'CPY',
      decimals: 18,
      chainId: 25,
      logoURI: ''
    },
    {
      name: 'Loot Network',
      address: '0xEd34211cDD2cf76C3cceE162761A72d7b6601E2B',
      symbol: 'LOOT',
      decimals: 18,
      chainId: 25,
      logoURI: ''
    },
    {
      name: 'VVSToken',
      address: '0x2D03bECE6747ADC00E1a131BBA1469C15fD11e03',
      symbol: 'VVS',
      decimals: 18,
      chainId: 25,
      logoURI: ''
    },
    {
      name: 'CronaSwap Token',
      address: '0xadbd1231fb360047525BEdF962581F3eee7b49fe',
      symbol: 'CRONA',
      decimals: 18,
      chainId: 25,
      logoURI: ''
    },
    {
      name: 'Crodex Token',
      address: '0xe243CCab9E66E6cF1215376980811ddf1eb7F689',
      symbol: 'CRX',
      decimals: 18,
      chainId: 25,
      logoURI: ''
    }
  ]
}
