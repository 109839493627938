import styled from 'styled-components'

const FormBlockContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.bg11};
  background-color: ${({ theme }) => theme.bg10};
  backdrop-filter: blur(140px);
  border-radius: 8px;
  margin-top: 1rem;
  padding: 1rem;
`

export default FormBlockContainer
