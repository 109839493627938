import { TokenItem } from './cryptoSwapp'

export interface Graph {
  name: string
  value: number
}

export interface TopSwappLiquidity {
  token0: TokenItem
  token1: TokenItem
  liquidity: number
  volume24h: number
  price: number
  fees24h: number
  fees7d: number
  graph7d: Graph[]
}

export const TopSwappLiquidityData: TopSwappLiquidity[] = [
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'Tether USD',
      symbol: 'USDT'
    },
    liquidity: 335300141.56,
    volume24h: 100192882.38,
    price: 99343.09,
    fees24h: 4.23,
    fees7d: -4.2,
    graph7d: [
      { name: '1', value: 10 },
      { name: '2', value: 25 },
      { name: '3', value: 5 },
      { name: '4', value: 42 },
      { name: '5', value: 13 },
      { name: '6', value: 44 },
      { name: '7', value: 17 }
    ]
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'CRO',
      symbol: 'CRO'
    },
    liquidity: 255300141.56,
    volume24h: 99192882.38,
    price: 74343.09,
    fees24h: 5.8,
    fees7d: 1.2,
    graph7d: [
      { name: '1', value: 25 },
      { name: '2', value: 10 },
      { name: '3', value: 15 },
      { name: '4', value: 3 },
      { name: '5', value: 21 },
      { name: '6', value: 30 },
      { name: '7', value: 5 }
    ]
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'Wrapped CRO',
      symbol: 'WCRO'
    },
    liquidity: 189300151.12,
    volume24h: 146192882.38,
    price: 102343.88,
    fees24h: -2.8,
    fees7d: -3.4,
    graph7d: [
      { name: '1', value: 10 },
      { name: '2', value: 12 },
      { name: '3', value: 30 },
      { name: '4', value: 18 },
      { name: '5', value: 22 },
      { name: '6', value: 7 },
      { name: '7', value: 13 }
    ]
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'USD//C',
      symbol: 'USDC'
    },
    liquidity: 213300141.56,
    volume24h: 140192882.38,
    price: 88343.88,
    fees24h: 4.1,
    fees7d: 5.4,
    graph7d: [
      { name: '1', value: 30 },
      { name: '2', value: 40 },
      { name: '3', value: 55 },
      { name: '4', value: 25 },
      { name: '5', value: 18 },
      { name: '6', value: 37 },
      { name: '7', value: 5 }
    ]
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'Wrapped BTC',
      symbol: 'WBTC'
    },
    liquidity: 185300141.56,
    volume24h: 130192882.38,
    price: 123343.88,
    fees24h: 2.7,
    fees7d: -1.4,
    graph7d: [
      { name: '1', value: 5 },
      { name: '2', value: 28 },
      { name: '3', value: 13 },
      { name: '4', value: 16 },
      { name: '5', value: 22 },
      { name: '6', value: 37 },
      { name: '7', value: 5 }
    ]
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'Dai Stablecoin',
      symbol: 'DAI'
    },
    liquidity: 425300141.56,
    volume24h: 210192882.38,
    price: 78343.88,
    fees24h: -3.5,
    fees7d: 2.4,
    graph7d: [
      { name: '1', value: 15 },
      { name: '2', value: 22 },
      { name: '3', value: 7 },
      { name: '4', value: 25 },
      { name: '5', value: 35 },
      { name: '6', value: 55 },
      { name: '7', value: 5 }
    ]
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'Wrapped ETH',
      symbol: 'WETH'
    },
    liquidity: 275300141.56,
    volume24h: 90192882.38,
    price: 78343.88,
    fees24h: 2.5,
    fees7d: 1.4,
    graph7d: [
      { name: '1', value: 10 },
      { name: '2', value: 55 },
      { name: '3', value: 17 },
      { name: '4', value: 25 },
      { name: '5', value: 15 },
      { name: '6', value: 5 },
      { name: '7', value: 25 }
    ]
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'Hibiki.finance',
      symbol: 'cHIBIKI'
    },
    liquidity: 415300141.56,
    volume24h: 220192882.38,
    price: 122343.88,
    fees24h: 5.2,
    fees7d: 2.4,
    graph7d: [
      { name: '1', value: 35 },
      { name: '2', value: 65 },
      { name: '3', value: 17 },
      { name: '4', value: 25 },
      { name: '5', value: 10 },
      { name: '6', value: 5 },
      { name: '7', value: 25 }
    ]
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'CronosPunkYield',
      symbol: 'CPY'
    },
    liquidity: 275300141.56,
    volume24h: 180192882.38,
    price: 95343.88,
    fees24h: 3.2,
    fees7d: -4.4,
    graph7d: [
      { name: '1', value: 35 },
      { name: '2', value: 41 },
      { name: '3', value: 22 },
      { name: '4', value: 25 },
      { name: '5', value: 10 },
      { name: '6', value: 5 },
      { name: '7', value: 1 }
    ]
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'ETH',
      symbol: 'ETH'
    },
    liquidity: 245300141.56,
    volume24h: 220192882.38,
    price: 150343.88,
    fees24h: 7.5,
    fees7d: 2.8,
    graph7d: [
      { name: '1', value: 5 },
      { name: '2', value: 10 },
      { name: '3', value: 16 },
      { name: '4', value: 28 },
      { name: '5', value: 18 },
      { name: '6', value: 35 },
      { name: '7', value: 45 }
    ]
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'ETH',
      symbol: 'ETH'
    },
    liquidity: 245300141.56,
    volume24h: 220192882.38,
    price: 150343.88,
    fees24h: 7.5,
    fees7d: 2.8,
    graph7d: [
      { name: '1', value: 5 },
      { name: '2', value: 10 },
      { name: '3', value: 16 },
      { name: '4', value: 28 },
      { name: '5', value: 18 },
      { name: '6', value: 35 },
      { name: '7', value: 45 }
    ]
  }
]
