import React, { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import { unixToDate } from '../../utils/date'
import { PageWrapper } from '../styled'
import { AutoColumn } from '../../components/Column'
import { TYPE } from '../../theme'
import { ResponsiveRow, RowBetween, RowOptionChart } from '../../components/Row'
import ActiveNetworkVersion from '../../constants/mocks/activeNetworkVersion'
import { formatDollar } from '../../utils/numbers'
import { MonoSpace } from '../../components/shared'
import BarChart from '../../components/BarChart/alt'
import LineChart from '../../components/LineChart/alt'
import { VolumeWindow } from '../../types'
import { useTransformedVolumeData } from '../../hooks/chart'
import useTheme from '../../hooks/useTheme'
import ProtocolData from '../../constants/mocks/protocolData'
import ChartData from '../../constants/mocks/chartData'
import { useSelectBackgroundUrl } from '../../state/application/hooks'
import { CustomInput } from '../../components/Inputs/StyledInput'
import { AnalyticsHeaderWrapper } from './styledComponents'
import { SmallOptionChartButton } from '../../components/Button'
import CryptoSwappTable from '../../components/CryptoSwappTable/CryptoSwappTable'
import { cryptoSwappMock } from '../../constants/mocks/cryptoSwapp'
import { LiquidityPairsTable } from '../../components/LiquidityPairsTable/LiquidityPairsTable'
import { LiquidityPairsData } from '../../constants/mocks/liquidityPairs'
import { TopSwappLiquidityTable } from '../../components/TopSwappLiquidityTable/TopSwappLiquidityTable'
import { TopSwappLiquidityData } from '../../constants/mocks/topSwappLiquidity'

const ChartWrapper = styled.div`
  width: 49%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`

export default function Analytics() {
  const setUrlNone = useSelectBackgroundUrl('none')
  const setUrlImg = useSelectBackgroundUrl('./background.png')

  useEffect(() => {
    setUrlNone()
    window.scrollTo(0, 0)
    return () => setUrlImg()
  }, [setUrlImg, setUrlNone])

  const theme = useTheme()
  // const [protocolData] = useProtocolData()
  // const [chartData] = useProtocolChartData()
  // const [transactions] = useProtocolTransactions()
  // const [activeNetwork] = useActiveNetworkVersion()
  // const transactions = TransactionData
  const activeNetwork = ActiveNetworkVersion
  const protocolData = ProtocolData
  const chartData = ChartData
  const liquidityData = LiquidityPairsData

  const swappData = cryptoSwappMock
  const topSwappLiquidity = TopSwappLiquidityData
  const [volumeHover, setVolumeHover] = useState<number | undefined>(undefined)
  const [liquidityHover, setLiquidityHover] = useState<number | undefined>(undefined)
  const [leftLabel, setLeftLabel] = useState<string | undefined>(undefined)
  const [rightLabel, setRightLabel] = useState<string | undefined>(undefined)

  useEffect(() => {
    setLiquidityHover(undefined)
    setVolumeHover(undefined)
  }, [activeNetwork])

  // if hover value undefined, reset to current day value
  useEffect(() => {
    if (volumeHover === undefined && protocolData) {
      setVolumeHover(protocolData.volumeUSD)
    }
  }, [protocolData, volumeHover])
  useEffect(() => {
    if (liquidityHover === undefined && protocolData) {
      setLiquidityHover(protocolData.tvlUSD)
    }
  }, [liquidityHover, protocolData])

  const formattedVolumeData = useMemo(() => {
    if (chartData) {
      return chartData.map(day => {
        return {
          time: unixToDate(day.date),
          value: day.volumeUSD
        }
      })
    } else {
      return []
    }
  }, [chartData])

  const weeklyVolumeData = useTransformedVolumeData(chartData, 'week')
  const monthlyVolumeData = useTransformedVolumeData(chartData, 'month')

  const [volumeChartWindow, setVolumeChartWindow] = useState(VolumeWindow.all)
  const [volumeLineWindow, setVolumeLineWindow] = useState(VolumeWindow.all)
  const [coin, setCoin] = useState('usd')

  return (
    <PageWrapper>
      <AutoColumn gap="16px">
        <AnalyticsHeaderWrapper>
          <TYPE.pageLabel>Analytics</TYPE.pageLabel>
          <CustomInput placeholder="Looking for something?" />
        </AnalyticsHeaderWrapper>
        <ResponsiveRow>
          <ChartWrapper>
            <LineChart
              data={
                volumeLineWindow === VolumeWindow.monthly
                  ? monthlyVolumeData
                  : volumeLineWindow === VolumeWindow.weekly
                  ? weeklyVolumeData
                  : formattedVolumeData
              }
              height={220}
              minHeight={325}
              color={'#246CF9'}
              value={liquidityHover}
              label={leftLabel}
              setValue={setLiquidityHover}
              setLabel={setLeftLabel}
              bottomLeft={
                <RowOptionChart width={'70px'}>
                  <SmallOptionChartButton active={coin === 'usd'} onClick={() => setCoin('usd')}>
                    USD
                  </SmallOptionChartButton>
                  <SmallOptionChartButton active={coin === 'avax'} onClick={() => setCoin('avax')}>
                    AVAX
                  </SmallOptionChartButton>
                </RowOptionChart>
              }
              topRight={
                <RowOptionChart>
                  <SmallOptionChartButton
                    active={volumeLineWindow === VolumeWindow.weekly}
                    onClick={() => setVolumeLineWindow(VolumeWindow.weekly)}
                  >
                    1week
                  </SmallOptionChartButton>
                  <SmallOptionChartButton
                    active={volumeLineWindow === VolumeWindow.monthly}
                    onClick={() => setVolumeLineWindow(VolumeWindow.monthly)}
                  >
                    1month
                  </SmallOptionChartButton>
                  <SmallOptionChartButton
                    active={volumeLineWindow === VolumeWindow.all}
                    onClick={() => setVolumeLineWindow(VolumeWindow.all)}
                  >
                    All
                  </SmallOptionChartButton>
                </RowOptionChart>
              }
              topLeft={
                <AutoColumn gap="10px">
                  <TYPE.barLabel fontSize="16px">Liquidity</TYPE.barLabel>
                  <TYPE.largeHeader fontSize="24px">
                    <MonoSpace> {formatDollar(liquidityHover)}</MonoSpace>
                  </TYPE.largeHeader>
                  <TYPE.barLabel fontSize="16px">{leftLabel ? <MonoSpace>{leftLabel}</MonoSpace> : null}</TYPE.barLabel>
                </AutoColumn>
              }
            />
          </ChartWrapper>
          <ChartWrapper>
            <BarChart
              height={220}
              minHeight={325}
              data={
                volumeChartWindow === VolumeWindow.monthly
                  ? monthlyVolumeData
                  : volumeChartWindow === VolumeWindow.weekly
                  ? weeklyVolumeData
                  : formattedVolumeData
              }
              color={theme.blue1}
              setValue={setVolumeHover}
              setLabel={setRightLabel}
              value={volumeHover}
              label={rightLabel}
              activeWindow={volumeChartWindow}
              topRight={
                <RowOptionChart>
                  <SmallOptionChartButton
                    active={volumeChartWindow === VolumeWindow.weekly}
                    onClick={() => setVolumeChartWindow(VolumeWindow.weekly)}
                  >
                    1week
                  </SmallOptionChartButton>
                  <SmallOptionChartButton
                    active={volumeChartWindow === VolumeWindow.monthly}
                    onClick={() => setVolumeChartWindow(VolumeWindow.monthly)}
                  >
                    1month
                  </SmallOptionChartButton>
                  <SmallOptionChartButton
                    active={volumeChartWindow === VolumeWindow.all}
                    onClick={() => setVolumeChartWindow(VolumeWindow.all)}
                  >
                    All
                  </SmallOptionChartButton>
                </RowOptionChart>
              }
              topLeft={
                <AutoColumn gap="10px">
                  <TYPE.barLabel fontSize="16px">Volume</TYPE.barLabel>
                  <TYPE.largeHeader fontSize="24px">
                    <MonoSpace> {formatDollar(volumeHover)}</MonoSpace>
                  </TYPE.largeHeader>
                  <TYPE.barLabel fontSize="16px">
                    {rightLabel ? <MonoSpace>{rightLabel}</MonoSpace> : null}
                  </TYPE.barLabel>
                </AutoColumn>
              }
            />
          </ChartWrapper>
        </ResponsiveRow>
        <RowBetween>
          <TYPE.analyticsTableLabel style={{ marginTop: '40px', marginBottom: '20px' }}>
            CryptoSwapp
          </TYPE.analyticsTableLabel>
        </RowBetween>
        <CryptoSwappTable data={swappData} />
        <RowBetween>
          <TYPE.analyticsTableLabel style={{ marginTop: '40px', marginBottom: '20px' }}>
            Top Joe Liquidity Pairs
          </TYPE.analyticsTableLabel>
        </RowBetween>
        <LiquidityPairsTable data={liquidityData} />
        <RowBetween>
          <TYPE.analyticsTableLabel style={{ marginTop: '40px', marginBottom: '20px' }}>
            Top CryptoSwapp Liquidity Pairs
          </TYPE.analyticsTableLabel>
        </RowBetween>
        <TopSwappLiquidityTable data={topSwappLiquidity} />
      </AutoColumn>
    </PageWrapper>
  )
}
