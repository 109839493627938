import React from 'react'
import { AutoColumn } from '../Column'
import AddLiquidityIcon from '../../assets/svg/AddLiquidity.svg'
import { ArrowRight } from 'react-feather'
import styled from 'styled-components'

const CenterButtonWrapper = styled(AutoColumn)`
  z-index: 3;
  position: relative;
  padding: 0.5rem;
  & > * {
    margin-top: -31px;
    margin-left: -23px;
    position: absolute;
  }
`

const IconWrapper = styled.div`
  background-color: ${({ theme }) => theme.primary1};
  border-radius: 31px;
  height: 62px;
  width: 62px;
  color: ${({ theme }) => theme.text1};
`

export default function CenterButton({ icon, ...rest }: { icon?: string }) {
  return (
    <CenterButtonWrapper justify="space-between" {...rest}>
      {icon && icon === 'arrow-right' ? (
        <IconWrapper>
          <ArrowRight />
        </IconWrapper>
      ) : (
        <img height={'62px'} src={AddLiquidityIcon} alt={'liquidity icon'} />
      )}
    </CenterButtonWrapper>
  )
}
