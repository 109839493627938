import React from 'react'
import { LineChart, Line } from 'recharts'
import { Graph } from '../../constants/mocks/topSwappLiquidity'

interface TableLineChartProps {
  data: Graph[]
  color?: string
}

export const TableLineChart: React.FC<TableLineChartProps> = ({ data, color = '#6FCF97' }) => {
  return (
    <LineChart width={250} height={50} data={data}>
      <defs>
        <filter id={`tableFilter_${color}`}>
          <feDropShadow dx="0" dy="0.01" floodColor={color} />
          <feDropShadow dx="0" dy="-0.01" floodColor={color} />
        </filter>
      </defs>
      <Line
        type="linear"
        dot={false}
        dataKey="value"
        strokeWidth={1}
        stroke={color}
        isAnimationActive={false}
        filter={`url(#tableFilter_${color})`}
      />
    </LineChart>
  )
}
