import React, { useContext, useMemo } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Pair } from '@uniswap/sdk'
import { Link } from 'react-router-dom'
import { SwapPoolTabs } from '../../components/NavigationTabs'

import FullPositionCard from '../../components/PositionCard'
import { useUserHasLiquidityInAllTokens } from '../../data/V1'
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { TYPE } from '../../theme'
import { Text } from 'rebass'
import AppBody from '../AppBody'
import Card from '../../components/Card'
import { ButtonPrimary, ButtonSecondary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'

import { useActiveWeb3React } from '../../hooks'
import { usePairs } from '../../data/Reserves'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import { Dots } from '../../components/swap/styleds'
import { Plus } from 'react-feather'
import FormBlockContainer from '../../components/FormBlockContainer'

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

const ResponsiveButtonSecondary = styled(ButtonSecondary)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall` 
    width: 48%;
  `};
`

const EmptyProposals = styled.div`
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`

const BodyWrapper = styled(FormBlockContainer)`
  margin-top: 0;
  .button {
    padding: 1rem;
    font-size: 14px;
    font-weight: bold;
  }
  .button-primary {
    margin-top: 1rem;
    width: 100%;
  }
  #find-tokens-button {
    padding: 11px 21px;
    border-radius: 21px;
    color: ${({ theme }) => theme.text1};
    min-width: 183px;
    margin: auto;
  }
`

export default function Pool() {
  const theme = useContext(ThemeContext)
  const { account } = useActiveWeb3React()

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map(tokens => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map(tpwlt => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  // console.log('v2Pairs', v2Pairs)
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some(V2Pair => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))
  // console.log('allV2PairsWithLiquidity', allV2PairsWithLiquidity)

  const hasV1Liquidity = useUserHasLiquidityInAllTokens()

  return (
    <>
      <h1>Add Liquidity</h1>
      <h2>Remove liquidity to receive tokens back</h2>
      <SwapPoolTabs active={'pool'} />
      <AppBody>
        <BodyWrapper>
          {!account ? (
            <Card padding="40px">
              <TYPE.body color={theme.text3} textAlign="center">
                Connect to a wallet to view your liquidity.
              </TYPE.body>
            </Card>
          ) : v2IsLoading ? (
            <EmptyProposals>
              <Dots>Loading</Dots>
            </EmptyProposals>
          ) : allV2PairsWithLiquidity?.length > 0 ? (
            <>
              {allV2PairsWithLiquidity.map(v2Pair => (
                <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
              ))}
            </>
          ) : (
            <EmptyProposals>No liquidity found.</EmptyProposals>
          )}
          <AutoColumn justify={'center'} gap="md">
            <Text textAlign="center" fontSize={14} style={{ padding: '1rem 0' }}>
              {hasV1Liquidity ? 'Swapp liquidity found!' : "Don't see a pool you joined?"}{' '}
            </Text>
          </AutoColumn>
          {!hasV1Liquidity && (
            <ResponsiveButtonSecondary id="find-tokens-button" as={Link} to={'/find'} className={'button button-secondary'}>
              Find other LP tokens
            </ResponsiveButtonSecondary>
          )}
          <ResponsiveButtonPrimary id="create-pair-button" as={Link} to="/create/CRO" className={'button button-primary'}>
            Create a pair
          </ResponsiveButtonPrimary>
          <ResponsiveButtonPrimary id="join-pool-button" as={Link} padding="6px 8px" to="/add/CRO" className={'button button-primary'}>
            <Plus size="16"/> Add Liquidity
          </ResponsiveButtonPrimary>
        </BodyWrapper>
      </AppBody>
    </>
  )
}
