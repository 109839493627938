import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ReactComponent as SortArrowUp } from '../assets/svg/tables/sortArrowUp.svg'
import { ReactComponent as SortArrowDown } from '../assets/svg/tables/sortArrowDown.svg'

interface PropsHook<T> {
  rows: T[]
  maxItems: number
  sortName: string
}

export const useTableSort = <T extends unknown>({ rows, maxItems, sortName }: PropsHook<T>) => {
  const [sortField, setSortField] = useState<string>(sortName)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)

  useEffect(() => {
    let extraPages = 1
    if (rows) {
      if (rows.length % maxItems === 0) {
        extraPages = 0
      }
      setMaxPage(Math.floor(rows.length / maxItems) + extraPages)
    }
  }, [maxItems, rows])

  const sortedData = useMemo(() => {
    return rows
      ? rows
          .sort((a, b) => {
            if (a && b) {
              return a[sortField as keyof T] > b[sortField as keyof T]
                ? (sortDirection ? -1 : 1) * 1
                : (sortDirection ? -1 : 1) * -1
            } else {
              return -1
            }
          })
          .slice(maxItems * (page - 1), page * maxItems)
      : []
  }, [rows, maxItems, page, sortDirection, sortField])

  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField]
  )

  const arrow = useCallback(
    (field: string) => {
      return sortField === field ? (
        !sortDirection ? (
          <SortArrowUp style={{ marginLeft: '10px' }} />
        ) : (
          <SortArrowDown style={{ marginLeft: '10px' }} />
        )
      ) : (
        ''
      )
    },
    [sortDirection, sortField]
  )

  return {
    setPage,
    maxPage,
    sortedData,
    handleSort,
    arrow,
    page
  }
}
