import { Currency, Pair } from '@uniswap/sdk'
import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import CurrencyLogo from '../CurrencyLogo'
import { DoubleCurrencyLogo } from '../DoubleLogo'
import FormBlockContainer from '../../components/FormBlockContainer'
import { Input as NumericalInput } from '../NumericalInput'
import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'

import { useActiveWeb3React } from '../../hooks'
import { useTranslation } from 'react-i18next'
import { CardProps } from 'rebass/styled-components'

const InputColumn = styled.div<{ selected: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  align-items: center;
  padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 1rem')};
  width: 100%;
  & > * {
    width: 100%;
  }
`

const CurrencySelect = styled.div<{ selected: boolean }>`
  align-items: center;
  height: 2.2rem;
  font-size: 20px;
  font-weight: 500;
  color: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
  outline: none;
  cursor: pointer;
  user-select: none;

  :focus,
  :hover {
    transition: linear 0.2s;
    color: ${({ theme }) => theme.primary1};
    path {
      stroke: ${({ theme }) => theme.primary1};
    }
  }

  border-bottom: solid 1px rgba(255, 255, 255, 0.12);
`

const LabelColumn = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.text1};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0 1rem;
  line-height: 28px;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
  img {
    margin-right: 8px;
  }
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.5rem;
  height: 35%;

  path {
    stroke: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
    stroke-width: 1.5px;
  }
`

const InputPanel = styled.div<{ hideInput?: boolean; width?: string }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  border-radius: 8px;
  z-index: 1;
  justify-content: stretch;
  align-self: stretch;
  width: ${({ width }) => (width ? width : '50%')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    margin-bottom: 20px;
  `}
`

const Container = styled(FormBlockContainer)<{ hideInput: boolean; align?: string }>`
  height: 100%;
  margin-top: 0;
  padding: 0;
  .balance-wrapper,
  .max-wrapper {
    text-align: ${({ align }) => (align ? align : 'left')};
  }
`

const StyledTokenName = styled.span<{ active?: boolean }>`
  ${({ active }) => (active ? '  margin: 0 0.25rem 0 0;' : '  margin: 0 0.25rem 0 0;')}
  font-size:  ${({ active }) => (active ? '20px' : '16px')};

`

const StyledBalanceMax = styled.button`
  padding: 3px 8px;
  background-color: ${({ theme }) => theme.bg8};
  border: none;
  border-radius: 4px;
  font-size: 14px;
  width: auto;

  font-weight: 500;
  cursor: pointer;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.text1};
  :hover,
  :focus {
    opacity: 0.8;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-right: 0.5rem;
  `};
`

const TokenName = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.text7};
`

const DirectionLabel = styled.div<{ label: string }>`
  color: ${({ label, theme }) => (label === 'To' || label === 'Output' ? theme.green2 : theme.yellow3)};
`

const Balance = styled.div`
  color: ${({ theme }) => theme.text7};
  font-size: 14px;
  cursor: pointer;
  margin: 20px 0 4px 0;
  height: 17px;
`

const BalanceMaxWrapper = styled.div`
  height: 23px;
  margin-bottom: 40px;
  & > * {
    margin-right: 0;
  }
`

const Amount = styled(NumericalInput)`
  ::placeholder {
    color: ${({ theme }) => theme.text7};
  }
  background-color: transparent;
  padding: 23px 0;
  :focus {
    border-bottom: solid 1px ${({ theme }) => theme.bg8};
  }
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  customBalanceText?: string
  align?: string | 'left'
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label = 'Input',
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases,
  customBalanceText,
  align,
  ...rest
}: CurrencyInputPanelProps & CardProps) {
  const { t } = useTranslation()

  const [modalOpen, setModalOpen] = useState(false)
  const { account } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  return (
    <InputPanel id={id} {...rest}>
      <Container hideInput={hideInput} align={align}>
        {!hideInput && (
          <LabelColumn>
            {pair ? (
              <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={28} margin={true} />
            ) : currency ? (
              <CurrencyLogo currency={currency} size={'28px'} />
            ) : null}
            {pair ? (
              <TokenName>
                {pair?.token0.name}:{pair?.token1.name}
              </TokenName>
            ) : (
              <TokenName>
                {(currency && currency.name ? currency.name : currency?.symbol) || t('selectToken')}
              </TokenName>
            )}
          </LabelColumn>
        )}
        <InputColumn style={hideInput ? { padding: '0', borderRadius: '8px' } : {}} selected={disableCurrencySelect}>
          <CurrencySelect
            selected={!!currency}
            className="open-currency-select-button"
            onClick={() => {
              if (!disableCurrencySelect) {
                setModalOpen(true)
              }
            }}
          >
            <Aligner>
              {pair ? (
                <StyledTokenName className="pair-name-container">
                  {pair?.token0.name}:{pair?.token1.name}
                </StyledTokenName>
              ) : (
                <StyledTokenName className="token-symbol-container" active={Boolean(currency && currency.symbol)}>
                  {(currency && currency.symbol && currency.symbol.length > 20
                    ? currency.symbol.slice(0, 4) +
                      '...' +
                      currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                    : currency?.symbol) || t('selectToken')}
                </StyledTokenName>
              )}
              {!disableCurrencySelect && <StyledDropDown selected={!!currency} />}
            </Aligner>
          </CurrencySelect>
          <div>
            {account && (
              <Balance onClick={onMax} className="balance-wrapper">
                {!hideBalance && !!currency && selectedCurrencyBalance
                  ? (customBalanceText ?? 'Balance: ') + selectedCurrencyBalance?.toSignificant(6)
                  : ' '}
              </Balance>
            )}
          </div>
          {!hideInput && (
            <>
              <BalanceMaxWrapper className="max-wrapper">
                {account && currency && showMaxButton && label !== 'To' && (
                  <StyledBalanceMax onClick={onMax}>MAX</StyledBalanceMax>
                )}
              </BalanceMaxWrapper>

              <DirectionLabel label={label}>
                {label === 'To' ? 'You Get' : label === 'From' ? 'You Send' : label}
              </DirectionLabel>
              <Amount
                className="token-amount-input"
                value={value}
                onUserInput={val => {
                  onUserInput(val)
                }}
              />
            </>
          )}
        </InputColumn>
      </Container>
      {!disableCurrencySelect && onCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
        />
      )}
    </InputPanel>
  )
}
