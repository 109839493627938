import React from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
import { STAKING_REWARDS_INFO, useStakingInfo } from '../../state/stake/hooks'
import { TYPE, ExternalLink, StyledInternalLink } from '../../theme'
import PoolCard from '../../components/earn/PoolCard'
import { RowBetween } from '../../components/Row'
import { CardSection, DataCard, CardNoise, CardBGImage } from '../../components/earn/styled'
import { Countdown } from './Countdown'
import Loader from '../../components/Loader'
import { useActiveWeb3React } from '../../hooks'
import { SITE_URL } from '../../connectors'
import { useLocation } from 'react-router-dom'
import { ButtonPrimary } from '../../components/Button'
import { CURRENT_REWARDS_ROUND } from '../../constants'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const TopSection = styled(AutoColumn)`
  max-width: 720px;
  width: 100%;
`

const PoolSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  row-gap: 15px;
  width: 100%;
  justify-self: center;
`
const RoundSection = styled.div`
  display: inline-flex;
`

export default function Earn() {
  const { chainId } = useActiveWeb3React()
  const stakingInfos = useStakingInfo()

  const queries = useLocation().search

  const roundFromQS = new URLSearchParams(queries).get('round')
  const roundInfo = roundFromQS ? parseInt(roundFromQS) : CURRENT_REWARDS_ROUND

  const roundArr = []
  if (roundInfo !== undefined) {
    for (let i = 0; i <= CURRENT_REWARDS_ROUND; i++) {
      roundArr.push(i)
    }
  }
  // const stakingInfos1 = stakingInfos.filter(item => item.finishGroup === 1)
  // const stakingInfos2 = stakingInfos.filter(item => item.finishGroup === 0)
  const stakingInfosFiltered = stakingInfos.filter(item => item.round === roundInfo)

  const DataRow = styled(RowBetween)`
    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
  `

  const stakingRewardsExist = Boolean(typeof chainId === 'number' && (STAKING_REWARDS_INFO[chainId]?.length ?? 0) > 0)

  return (
    <PageWrapper gap="lg" justify="center">
      <TopSection gap="md">
        <DataCard>
          <CardBGImage />
          <CardNoise />
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>Crypto Swapp liquidity mining</TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white fontSize={14}>Deposit your Liquidity Provider tokens to receive SWAPP tokens.</TYPE.white>
              </RowBetween>{' '}
              <ExternalLink style={{ color: 'white', textDecoration: 'underline' }} href={SITE_URL} target="_blank">
                <TYPE.white fontSize={14}>Read more about SWAPP</TYPE.white>
              </ExternalLink>
            </AutoColumn>
          </CardSection>
          <CardBGImage />
          <CardNoise />
        </DataCard>
      </TopSection>

      <RoundSection>
        {roundArr
          ? roundArr?.map(roundItem => {
              return (
                <StyledInternalLink to={`/farming/pair?round=${roundItem}`}>
                  <ButtonPrimary
                    width="auto"
                    margin="4px"
                    padding="15px"
                    borderRadius="8px"
                    backgroundColor={roundItem === roundInfo ? '#5b5eb1!important' : ''}
                  >
                    Round {roundItem}
                  </ButtonPrimary>
                </StyledInternalLink>
              )
            })
          : 0}
      </RoundSection>

      <AutoColumn gap="lg" style={{ width: '100%', maxWidth: '720px' }}>
        <DataRow style={{ alignItems: 'baseline' }}>
          <TYPE.mediumHeader style={{ marginTop: '0.5rem' }}>Round {roundInfo} - Participating pools</TYPE.mediumHeader>
          <Countdown exactEnd={stakingInfosFiltered?.[0]?.periodFinish} />
        </DataRow>

        <PoolSection>
          {stakingRewardsExist && stakingInfosFiltered?.length === 0 ? (
            <Loader style={{ margin: 'auto' }} />
          ) : !stakingRewardsExist ? (
            'No active rewards'
          ) : (
            stakingInfosFiltered.map(stakingInfo => {
              // need to sort by added liquidity here
              return <PoolCard key={stakingInfo.stakingRewardAddress} stakingInfo={stakingInfo} />
            })
          )}
        </PoolSection>

        {/* {stakingRewardsExist && stakingInfos2?.length > 0 && (
          <DataRow style={{ alignItems: 'baseline' }}>
            <TYPE.mediumHeader style={{ marginTop: '0.5rem' }}> </TYPE.mediumHeader>
            <Countdown exactEnd={stakingInfos2?.[0]?.periodFinish} />
          </DataRow>
        )}
        <PoolSection>
          {stakingRewardsExist &&
            stakingInfos2?.length > 0 &&
            stakingInfos2?.map(stakingInfo => {
              // need to sort by added liquidity here
              return <PoolCard key={stakingInfo.stakingRewardAddress} stakingInfo={stakingInfo} />
            })}
        </PoolSection> */}
      </AutoColumn>
    </PageWrapper>
  )
}
