import React from 'react'
import styled from 'styled-components'
import { DarkGreyCard } from '../Card'
import Loader, { LoadingRows } from '../Loader'
import { AutoColumn } from '../Column'
import { Break, PageButtons } from '../shared'
import useTheme from '../../hooks/useTheme'
import { SwappItem } from '../../constants/mocks/cryptoSwapp'
import { DoubleLogo, getLogo } from '../DoubleLogo'
import { AnalyticsRowLabel, AnalyticsTableClickableText, Badge } from './components'
import { formatReserve, formatDollar } from '../../utils/numbers'
import { TableRightButton, TableLeftButton } from '../Arrows/TableArrows'
import { useTableSort } from '../../hooks/useTableSort'

const Wrapper = styled(DarkGreyCard)`
  width: 100%;
`

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;

  grid-template-columns: 1fr 0.8fr 0.4fr 0.6fr 0.5fr repeat(2, 1fr) 0.8fr;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 0.4fr 0.6fr 0.4fr repeat(2, 1fr) 0.8fr;
    & > :nth-child(2) {
      display: none;
    }
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr repeat(2, 1fr) 0.8fr;
    & > :nth-child(3) {
      display: none;
    }
    & > :nth-child(4) {
      display: none;
    }
    & > :nth-child(5) {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 1.5fr repeat(2, 1fr);
    & > :nth-child(7) {
      display: none;
    }
  }

  @media screen and (max-width: 670px) {
    grid-template-columns: repeat(2, 1fr);
    & :nth-child(6) {
      display: none;
    }
  }
`

const DataRow = ({ rowData, index }: { rowData: SwappItem; index: number }) => {
  const theme = useTheme()
  return (
    <ResponsiveGrid>
      <AnalyticsRowLabel color={theme.primary1}>
        <DoubleLogo name0={rowData.token0.name} name1={rowData.token1.name} />
        {rowData.token0.symbol}-{rowData.token1.symbol}
      </AnalyticsRowLabel>
      <AnalyticsRowLabel fontWeight={700}>{rowData.rewards} JOE per sec</AnalyticsRowLabel>
      <AnalyticsRowLabel position={'flex-end'}>
        <Badge color={rowData.changesDaily < 0 ? theme.red3 : theme.green3}>{rowData.changesDaily}</Badge>
      </AnalyticsRowLabel>
      <AnalyticsRowLabel position={'center'}>
        <Badge color={rowData.changesMonthly < 0 ? theme.red3 : theme.green3}>{rowData.changesMonthly}</Badge>
      </AnalyticsRowLabel>
      <AnalyticsRowLabel position={'flex-start'}>
        <Badge color={rowData.changesYearly < 0 ? theme.red3 : theme.green3}>{rowData.changesYearly}</Badge>
      </AnalyticsRowLabel>
      <AnalyticsRowLabel fontWeight={700}>
        {getLogo(rowData.token0.name)}&nbsp;
        {formatReserve(rowData.baseReserve)}&nbsp;
        {rowData.token0.symbol}
      </AnalyticsRowLabel>
      <AnalyticsRowLabel fontWeight={700}>
        {getLogo(rowData.token1.name)}&nbsp;
        {formatReserve(rowData.quoteReserve)}&nbsp;
        {rowData.token1.symbol}
      </AnalyticsRowLabel>
      <AnalyticsRowLabel fontWeight={700} position={'flex-end'}>
        {formatDollar(rowData.tvl)}
      </AnalyticsRowLabel>
    </ResponsiveGrid>
  )
}

const SORT_FIELD = {
  rewards: 'rewards',
  changesDaily: 'changesDaily',
  changesMonthly: 'changesMonthly',
  changesYearly: 'changesYearly',
  baseReserve: 'baseReserve',
  quoteReserve: 'quoteReserve',
  tvl: 'tvl'
}

const MAX_ITEMS = 9

export default function CryptoSwappTable({ data, maxItems = MAX_ITEMS }: { data: SwappItem[]; maxItems?: number }) {
  // theming
  const theme = useTheme()

  const { setPage, maxPage, sortedData, handleSort, arrow, page } = useTableSort<SwappItem>({
    rows: data,
    maxItems,
    sortName: SORT_FIELD.tvl
  })

  if (!data) {
    return <Loader />
  }

  return (
    <Wrapper>
      {data.length > 0 ? (
        <AutoColumn>
          <ResponsiveGrid>
            <AnalyticsTableClickableText color={theme.text2}>Name</AnalyticsTableClickableText>
            <AnalyticsTableClickableText color={theme.text2} onClick={() => handleSort(SORT_FIELD.rewards)}>
              Rewards {arrow(SORT_FIELD.rewards)}
            </AnalyticsTableClickableText>
            <AnalyticsTableClickableText
              color={theme.text2}
              position={'flex-end'}
              onClick={() => handleSort(SORT_FIELD.changesDaily)}
            >
              Daily {arrow(SORT_FIELD.changesDaily)}
            </AnalyticsTableClickableText>
            <AnalyticsTableClickableText
              color={theme.text2}
              position={'center'}
              onClick={() => handleSort(SORT_FIELD.changesMonthly)}
            >
              Monthly {arrow(SORT_FIELD.changesMonthly)}
            </AnalyticsTableClickableText>
            <AnalyticsTableClickableText color={theme.text2} onClick={() => handleSort(SORT_FIELD.changesYearly)}>
              Yearly {arrow(SORT_FIELD.changesYearly)}
            </AnalyticsTableClickableText>
            <AnalyticsTableClickableText color={theme.text2} onClick={() => handleSort(SORT_FIELD.baseReserve)}>
              Base Reserve {arrow(SORT_FIELD.baseReserve)}
            </AnalyticsTableClickableText>
            <AnalyticsTableClickableText color={theme.text2} onClick={() => handleSort(SORT_FIELD.quoteReserve)}>
              Quote Reserve {arrow(SORT_FIELD.quoteReserve)}
            </AnalyticsTableClickableText>
            <AnalyticsTableClickableText
              color={theme.text2}
              position={'flex-end'}
              onClick={() => handleSort(SORT_FIELD.tvl)}
            >
              TVL {arrow(SORT_FIELD.tvl)}
            </AnalyticsTableClickableText>
          </ResponsiveGrid>

          <Break />
          {sortedData.map((data, i) => {
            if (data) {
              return (
                <React.Fragment key={i}>
                  <DataRow index={(page - 1) * MAX_ITEMS + i} rowData={data} />
                  <Break />
                </React.Fragment>
              )
            }
            return null
          })}
          <PageButtons
            style={{
              marginTop: '30px'
            }}
          >
            <div
              onClick={() => {
                setPage(page === 1 ? page : page - 1)
              }}
            >
              <TableLeftButton faded={page === 1} />
            </div>
            <div
              onClick={() => {
                setPage(page === maxPage ? page : page + 1)
              }}
            >
              <TableRightButton faded={page === maxPage} />
            </div>
          </PageButtons>
        </AutoColumn>
      ) : (
        <LoadingRows>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </LoadingRows>
      )}
    </Wrapper>
  )
}
