import React from 'react'

import styled from 'styled-components'

interface Input {
  placeholder?: string
}

export const Input = styled.input`
  border: 2px solid ${({ theme }) => theme.border1};
  box-sizing: border-box;
  border-radius: 12px;
  width: 314px;
  height: 40px;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.bg0};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: ${({ theme }) => theme.text1};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`

export const CustomInput = (props: Input) => {
  return <Input {...props} />
}
