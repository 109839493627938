import { ChainId, CurrencyAmount, JSBI, Token, TokenAmount, WETH, Pair } from '@uniswap/sdk'
import { useMemo } from 'react'
import { DAI, SWAPP, USDC, USDT, BUSD, FTM, WBTC, WETH_, VVS, CRX, CRONA } from '../../constants'
import { STAKING_REWARDS_INTERFACE } from '../../constants/abis/staking-rewards'
import { useActiveWeb3React } from '../../hooks'
import { NEVER_RELOAD, useMultipleContractSingleData } from '../multicall/hooks'
import { tryParseAmount } from '../swap/hooks'

export const STAKING_GENESIS = 1641418200

export const REWARDS_DURATION_DAYS = 28

// TODO add staking rewards addresses here
export const STAKING_REWARDS_INFO: {
  [chainId in ChainId]?: {
    tokens: [Token, Token]
    stakingRewardAddress: string
    rewardToken: Token
    round: number
  }[]
} = {
  [ChainId.CRONOS]: [
    {
      tokens: [SWAPP[ChainId.CRONOS], WETH[ChainId.CRONOS]],
      stakingRewardAddress: '0xc03Eb0C87Da52709C444A27E4eafC30Fa56918D7',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 2
    },
    {
      tokens: [SWAPP[ChainId.CRONOS], WETH_],
      stakingRewardAddress: '0xEb9920Ced4839e8f424A7638F9F94D853A46C07D',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 2
    },
    {
      tokens: [SWAPP[ChainId.CRONOS], USDT],
      stakingRewardAddress: '0x4635910CC6528E15d3ef1E440DcD55Ec2e1be9Bf',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 2
    },
    {
      tokens: [SWAPP[ChainId.CRONOS], WBTC],
      stakingRewardAddress: '0x994ba21C65081e6f2e9487703C7E66c6bF0F7B57',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 2
    },
    {
      tokens: [WETH[ChainId.CRONOS], USDC],
      stakingRewardAddress: '0x7597B69FBFE3f41E77cAB2990FA32dCb80F67304',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 2
    },
    {
      tokens: [WETH[ChainId.CRONOS], WBTC],
      stakingRewardAddress: '0x21A3DA1A98262437850a631928d74e71C50e7C8E',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 2
    },
    {
      tokens: [WETH[ChainId.CRONOS], WETH_],
      stakingRewardAddress: '0x82351e32B6481EDb36Bae89538F2539B7A665cE0',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 2
    },
    {
      tokens: [WETH[ChainId.CRONOS], BUSD],
      stakingRewardAddress: '0xA764b43e3f7e5C75d1142231EeEB5Ecd9012cFcE',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 2
    },
    {
      tokens: [WETH[ChainId.CRONOS], DAI],
      stakingRewardAddress: '0x7E48e7643f3fA23E810413B9778F19C6b95B77bE',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 2
    },
    {
      tokens: [WETH[ChainId.CRONOS], FTM],
      stakingRewardAddress: '0xad3a8396083e96f06f78084187beEfbc79f4cAaC',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 2
    },
    {
      tokens: [USDT, USDC],
      stakingRewardAddress: '0xBb3a79d130B5aEb1Da17841b5Dc12e9a1ceb0f15',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 2
    },
    {
      tokens: [SWAPP[ChainId.CRONOS], WETH[ChainId.CRONOS]],
      stakingRewardAddress: '0xb015DBDAc9F09CbB23f80e382b342bfDbF4757FC',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 1
    },
    {
      tokens: [SWAPP[ChainId.CRONOS], WETH_],
      stakingRewardAddress: '0x458cF32b03cEebB3Ca86aB05e4CD1a22359E87C1',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 1
    },
    {
      tokens: [SWAPP[ChainId.CRONOS], USDT],
      stakingRewardAddress: '0x23e29E8cd3dDE6EC81bCc1308006c30941F2333c',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 1
    },
    {
      tokens: [SWAPP[ChainId.CRONOS], WBTC],
      stakingRewardAddress: '0x49fB4b14975CFA75Dc51EA85CaAF1ADd667EE424',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 1
    },
    {
      tokens: [WETH[ChainId.CRONOS], USDC],
      stakingRewardAddress: '0x45251454e016a5a26DbbC457b48fB6124180BC40',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 1
    },
    {
      tokens: [WETH[ChainId.CRONOS], WBTC],
      stakingRewardAddress: '0x5Bb132AC96dB6562872E89C17003232fC424b750',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 1
    },
    {
      tokens: [WETH[ChainId.CRONOS], WETH_],
      stakingRewardAddress: '0x838d2f161b2AbdA2CcA960b5acD7a5fe3324472D',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 1
    },
    {
      tokens: [WETH[ChainId.CRONOS], BUSD],
      stakingRewardAddress: '0x0952Cd993AA82Afee6BA3d4c188b6767C4bA69cf',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 1
    },
    {
      tokens: [WETH[ChainId.CRONOS], DAI],
      stakingRewardAddress: '0xc5183a15ac93Fe5A1c468B4C966737c833Aa6672',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 1
    },
    {
      tokens: [WETH[ChainId.CRONOS], FTM],
      stakingRewardAddress: '0x572c151B98dF1F52eB83416E504eA578aaa50baa',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 1
    },
    {
      tokens: [USDT, USDC],
      stakingRewardAddress: '0x8189939401582209C9Be0A10BaF1C4d6D10BeCA9',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 1
    },
    {
      tokens: [SWAPP[ChainId.CRONOS], WETH[ChainId.CRONOS]],
      stakingRewardAddress: '0xDD60747d9C7D6Bfc1E06CDE982E29aFB72E83767',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 0
    },
    {
      tokens: [SWAPP[ChainId.CRONOS], WETH_],
      stakingRewardAddress: '0xA4073C1a2d6eC97F601669cBE6018E66161cFDB9',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 0
    },
    {
      tokens: [SWAPP[ChainId.CRONOS], USDT],
      stakingRewardAddress: '0x5a26Dc6Ff9F00ac3d6913160a2F13A891d95Ffdf',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 0
    },
    {
      tokens: [SWAPP[ChainId.CRONOS], WBTC],
      stakingRewardAddress: '0xa298b738A51dA5C4522977e85d3Db38f15FD07C3',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 0
    },
    {
      tokens: [SWAPP[ChainId.CRONOS], VVS],
      stakingRewardAddress: '0x8f2f7267555AA1F469AD71efcf4AfcF4Fa5BEA57',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 0
    },
    {
      tokens: [SWAPP[ChainId.CRONOS], CRX],
      stakingRewardAddress: '0xC6b277685f20F11C688680F1E99b23ff425628e1',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 0
    },
    {
      tokens: [SWAPP[ChainId.CRONOS], CRONA],
      stakingRewardAddress: '0x6178f1d3172c1ae133892b2CAa5Ad99716c40783',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 0
    },
    {
      tokens: [WETH[ChainId.CRONOS], USDC],
      stakingRewardAddress: '0xd4e5F052E2829A6450E6Dd7Aca26683c48EAA1d0',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 0
    },
    {
      tokens: [WETH[ChainId.CRONOS], WBTC],
      stakingRewardAddress: '0x5a3F9Fba63e6B77A5EeF90e96A606f624BF0272d',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 0
    },
    {
      tokens: [WETH[ChainId.CRONOS], WETH_],
      stakingRewardAddress: '0x0B1726adDF9A956eff348c776292FFaf35c3889F',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 0
    },
    {
      tokens: [WETH[ChainId.CRONOS], BUSD],
      stakingRewardAddress: '0x4f5968535637876C395eefDEC6E0B56DC17E8158',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 0
    },
    {
      tokens: [WETH[ChainId.CRONOS], DAI],
      stakingRewardAddress: '0xDCB01A4417003b70EAB5f7A7751A5488908B7931',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 0
    },
    {
      tokens: [WETH[ChainId.CRONOS], FTM],
      stakingRewardAddress: '0x9b021fFC5FbE84e88c20be72fDd2f1c96Ea0C25D',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 0
    },
    {
      tokens: [USDT, USDC],
      stakingRewardAddress: '0x26eabd6A44fC76CF191E6c861883fa8a4Dd4A0B0',
      rewardToken: SWAPP[ChainId.CRONOS],
      round: 0
    }
  ]
}

export interface StakingInfo {
  // the address of the reward contract
  stakingRewardAddress: string
  // the tokens involved in this pair
  tokens: [Token, Token]
  // the reward tokens
  rewardToken: Token
  // the amount of token currently staked, or undefined if no account
  stakedAmount: TokenAmount
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: TokenAmount
  // the total amount of token staked in the contract
  totalStakedAmount: TokenAmount
  // the amount of token distributed per second to all LPs, constant
  totalRewardRate: TokenAmount
  // the current amount of token distributed to the active account per second.
  // equivalent to percent of total supply * reward rate
  rewardRate: TokenAmount
  // when the period ends
  periodFinish: Date | undefined
  // calculates a hypothetical amount of token distributed to the active account per second.
  getHypotheticalRewardRate: (
    stakedAmount: TokenAmount,
    totalStakedAmount: TokenAmount,
    totalRewardRate: TokenAmount
  ) => TokenAmount

  round: number
}

// gets the staking info from the network for the active chain id
export function useStakingInfo(pairToFilterBy?: Pair | null): StakingInfo[] {
  const { chainId, account } = useActiveWeb3React()

  const info = useMemo(
    () =>
      chainId
        ? STAKING_REWARDS_INFO[chainId]?.filter(stakingRewardInfo =>
            pairToFilterBy === undefined
              ? true
              : pairToFilterBy === null
              ? false
              : pairToFilterBy.involvesToken(stakingRewardInfo.tokens[0]) &&
                pairToFilterBy.involvesToken(stakingRewardInfo.tokens[1])
          ) ?? []
        : [],
    [chainId, pairToFilterBy]
  )

  const uni = chainId ? SWAPP[chainId] : undefined

  const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])

  const accountArg = useMemo(() => [account ?? undefined], [account])

  // get all the info from the staking rewards contracts
  const balances = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'balanceOf', accountArg)
  const earnedAmounts = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'earned', accountArg)
  const totalSupplies = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'totalSupply')

  // tokens per second, constants
  const rewardRates = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'rewardRate',
    undefined,
    NEVER_RELOAD
  )
  const periodFinishes = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'periodFinish',
    undefined,
    NEVER_RELOAD
  )

  return useMemo(() => {
    if (!chainId || !uni) return []

    return rewardsAddresses.reduce<StakingInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account
      const balanceState = balances[index]
      const earnedAmountState = earnedAmounts[index]

      // these get fetched regardless of account
      const totalSupplyState = totalSupplies[index]
      const rewardRateState = rewardRates[index]
      const periodFinishState = periodFinishes[index]

      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        !earnedAmountState?.loading &&
        // always need these
        totalSupplyState &&
        !totalSupplyState.loading &&
        rewardRateState &&
        !rewardRateState.loading &&
        periodFinishState &&
        !periodFinishState.loading
      ) {
        if (
          balanceState?.error ||
          earnedAmountState?.error ||
          totalSupplyState.error ||
          rewardRateState.error ||
          periodFinishState.error
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token
        const tokens = info[index].tokens
        const dummyPair = new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0'))

        // check for account, if no account set to 0

        const stakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
        const totalStakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(totalSupplyState.result?.[0]))
        const totalRewardRate = new TokenAmount(uni, JSBI.BigInt(rewardRateState.result?.[0]))

        const getHypotheticalRewardRate = (
          stakedAmount: TokenAmount,
          totalStakedAmount: TokenAmount,
          totalRewardRate: TokenAmount
        ): TokenAmount => {
          return new TokenAmount(
            uni,
            JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
              ? JSBI.divide(JSBI.multiply(totalRewardRate.raw, stakedAmount.raw), totalStakedAmount.raw)
              : JSBI.BigInt(0)
          )
        }

        const individualRewardRate = getHypotheticalRewardRate(stakedAmount, totalStakedAmount, totalRewardRate)

        const periodFinishMs = periodFinishState.result?.[0]?.mul(1000)?.toNumber()

        memo.push({
          stakingRewardAddress: rewardsAddress,
          tokens: info[index].tokens,
          rewardToken: info[index].rewardToken,
          periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
          earnedAmount: new TokenAmount(uni, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
          rewardRate: individualRewardRate,
          totalRewardRate: totalRewardRate,
          stakedAmount: stakedAmount,
          totalStakedAmount: totalStakedAmount,
          getHypotheticalRewardRate,
          round: info[index].round
        })
      }
      return memo
    }, [])
  }, [balances, chainId, earnedAmounts, info, periodFinishes, rewardRates, rewardsAddresses, totalSupplies, uni])
}

// gets the staking info from the network for the active chain id
export function useStakingInfo2(roundInfo?: number | null, rewardToFilterBy?: string | null): StakingInfo[] {
  const { chainId, account } = useActiveWeb3React()
  const info = useMemo(
    () =>
      chainId
        ? STAKING_REWARDS_INFO[chainId]?.filter(
            stakingRewardInfo =>
              roundInfo !== undefined && roundInfo === stakingRewardInfo.round
                ? true
                : rewardToFilterBy !== undefined && rewardToFilterBy === stakingRewardInfo.stakingRewardAddress // rewardToFilterBy.equals(stakingRewardInfo.token)
          ) ?? []
        : [],
    [chainId, roundInfo, rewardToFilterBy]
  )

  const uni = chainId ? SWAPP[chainId] : undefined

  const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])

  const accountArg = useMemo(() => [account ?? undefined], [account])

  // get all the info from the staking rewards contracts
  const balances = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'balanceOf', accountArg)
  const earnedAmounts = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'earned', accountArg)
  const totalSupplies = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'totalSupply')

  // tokens per second, constants
  const rewardRates = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'rewardRate',
    undefined,
    NEVER_RELOAD
  )
  const periodFinishes = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'periodFinish',
    undefined,
    NEVER_RELOAD
  )

  return useMemo(() => {
    if (!chainId || !uni) return []

    return rewardsAddresses.reduce<StakingInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account
      const balanceState = balances[index]
      const earnedAmountState = earnedAmounts[index]

      // these get fetched regardless of account
      const totalSupplyState = totalSupplies[index]
      const rewardRateState = rewardRates[index]
      const periodFinishState = periodFinishes[index]

      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        !earnedAmountState?.loading &&
        // always need these
        totalSupplyState &&
        !totalSupplyState.loading &&
        rewardRateState &&
        !rewardRateState.loading &&
        periodFinishState &&
        !periodFinishState.loading
      ) {
        if (
          balanceState?.error ||
          earnedAmountState?.error ||
          totalSupplyState.error ||
          rewardRateState.error ||
          periodFinishState.error
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token
        const tokens = info[index].tokens
        const dummyPair = new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0'))

        // check for account, if no account set to 0

        const stakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
        const totalStakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(totalSupplyState.result?.[0]))
        const totalRewardRate = new TokenAmount(info[index].rewardToken, JSBI.BigInt(rewardRateState.result?.[0]))

        const getHypotheticalRewardRate = (
          stakedAmount: TokenAmount,
          totalStakedAmount: TokenAmount,
          totalRewardRate: TokenAmount
        ): TokenAmount => {
          return new TokenAmount(
            info[index].rewardToken,
            JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
              ? JSBI.divide(JSBI.multiply(totalRewardRate.raw, stakedAmount.raw), totalStakedAmount.raw)
              : JSBI.BigInt(0)
          )
        }

        const individualRewardRate = getHypotheticalRewardRate(stakedAmount, totalStakedAmount, totalRewardRate)

        const periodFinishMs = periodFinishState.result?.[0]?.mul(1000)?.toNumber()

        memo.push({
          stakingRewardAddress: rewardsAddress,
          tokens: info[index].tokens,
          rewardToken: info[index].rewardToken,
          round: info[index].round,
          periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
          earnedAmount: new TokenAmount(info[index].rewardToken, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
          rewardRate: individualRewardRate,
          totalRewardRate: totalRewardRate,
          stakedAmount: stakedAmount,
          totalStakedAmount: totalStakedAmount,
          getHypotheticalRewardRate
        })
      }
      return memo
    }, [])
  }, [balances, chainId, earnedAmounts, info, periodFinishes, rewardRates, rewardsAddresses, totalSupplies, uni])
}

export function useTotalUniEarned(): TokenAmount | undefined {
  const { chainId } = useActiveWeb3React()
  const uni = chainId ? SWAPP[chainId] : undefined
  const stakingInfos = useStakingInfo()

  return useMemo(() => {
    if (!uni) return undefined
    return (
      stakingInfos?.reduce(
        (accumulator, stakingInfo) => accumulator.add(stakingInfo.earnedAmount),
        new TokenAmount(uni, '0')
      ) ?? new TokenAmount(uni, '0')
    )
  }, [stakingInfos, uni])
}

// based on typed value
export function useDerivedStakeInfo(
  typedValue: string,
  stakingToken: Token,
  userLiquidityUnstaked: TokenAmount | undefined
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingToken)

  const parsedAmount =
    parsedInput && userLiquidityUnstaked && JSBI.lessThanOrEqual(parsedInput.raw, userLiquidityUnstaked.raw)
      ? parsedInput
      : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error
  }
}

// based on typed value
export function useDerivedUnstakeInfo(
  typedValue: string,
  stakingAmount: TokenAmount
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingAmount.token)

  const parsedAmount = parsedInput && JSBI.lessThanOrEqual(parsedInput.raw, stakingAmount.raw) ? parsedInput : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error
  }
}
