import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { ArrowLeft } from 'react-feather'
import { AutoColumn } from '../Column'
import { Link } from 'react-router-dom'
import { ButtonSecondary } from '../Button'

const Tabs = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  border-radius: 3rem;
  justify-content: space-evenly;
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  height: 3rem;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  font-size: 20px;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.text1};
`

const HistoryButton = styled(ButtonSecondary)`
  width: fit-content;
  color: ${({ theme }) => theme.text1};
  margin: auto;
  padding: 0 2rem;
  font-weight: bold;
  border-radius: 21px;
  font-size: 14px;
  line-height: 41px;

  svg {
    margin-right: 1rem;
  }
  margin-bottom: 2rem;
  border-color: ${({ theme }) => theme.primary1};
  ${({ theme }) => theme.mediaWidth.upToSmall` 
    width: 70%;
  `};
`

export function SwapPoolTabs({ active }: { active: 'swap' | 'pool' }) {
  const { t } = useTranslation()
  return (
    <Tabs style={{ marginBottom: '20px', display: 'none' }}>
      <StyledNavLink id={`swap-nav-link`} to={'/swap'} isActive={() => active === 'swap'}>
        {t('swap')}
      </StyledNavLink>
      <StyledNavLink id={`pool-nav-link`} to={'/pool'} isActive={() => active === 'pool'}>
        {t('pool')}
      </StyledNavLink>
    </Tabs>
  )
}

export function FindPoolTabs() {
  return (
    <AutoColumn>
      <h1 style={{ marginBottom: '2rem' }}>Import Pool</h1>
      <HistoryButton as={Link} to={'/pool'}>
        <StyledArrowLeft /> Add Liquidity
      </HistoryButton>
    </AutoColumn>
  )
}

export function AddRemoveTabs({ adding, creating }: { adding: boolean; creating: boolean }) {
  return (
    <AutoColumn>
      <h1 style={{ marginBottom: !adding && !creating ? '2rem' : '' }}>
        {creating ? 'Create a pair' : adding ? 'Add Liquidity' : 'Remove Liquidity'}
      </h1>
      {adding && <h2 style={{ marginBottom: '2rem' }}>Add liquidity to receive LP tokens</h2>}
      {/*<QuestionHelper
          text={
            adding
              ? 'When you add liquidity, you are given pool tokens representing your position. These tokens automatically earn fees proportional to your share of the pool, and can be redeemed at any time.'
              : 'Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive.'
          }
        />*/}
      <HistoryButton as={Link} to={'/pool'}>
        <StyledArrowLeft /> Add Liquidity
      </HistoryButton>
    </AutoColumn>
  )
}
