import React, { Dispatch, SetStateAction, ReactNode } from 'react'
import { ResponsiveContainer, Tooltip, LineChart, Line } from 'recharts'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import useTheme from '../../hooks/useTheme'
import { RowBetween } from '../Row'
import { LabelAbsoluteLeft, LabelAbsoluteRight, WrapperChart } from '../BarChart/alt'
import styled from 'styled-components'

dayjs.extend(utc)

const DEFAULT_HEIGHT = 300

export const LabelAbsoluteLeftBottom = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
`

export type LineChartProps = {
  data: any[]
  color?: string | undefined
  height?: number | undefined
  minHeight?: number
  setValue?: Dispatch<SetStateAction<number | undefined>> // used for value on hover
  setLabel?: Dispatch<SetStateAction<string | undefined>> // used for label of valye
  value?: number
  label?: string
  topLeft?: ReactNode | undefined
  topRight?: ReactNode | undefined
  bottomLeft?: ReactNode | undefined
  bottomRight?: ReactNode | undefined
} & React.HTMLAttributes<HTMLDivElement>

const Chart = ({
  data,
  color = '#246CF9',
  value,
  label,
  setValue,
  setLabel,
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  minHeight = DEFAULT_HEIGHT,
  ...rest
}: LineChartProps) => {
  const theme = useTheme()
  const parsedValue = value

  return (
    <WrapperChart minHeight={minHeight} {...rest}>
      <RowBetween height={'160px'}>
        <LabelAbsoluteLeft>{topLeft ?? null}</LabelAbsoluteLeft>
        <LabelAbsoluteRight>{topRight ?? null}</LabelAbsoluteRight>
      </RowBetween>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data}
          onMouseLeave={() => {
            setLabel && setLabel(undefined)
            setValue && setValue(undefined)
          }}
        >
          <Tooltip
            cursor={{ stroke: theme.bg2 }}
            contentStyle={{ display: 'none' }}
            formatter={(value: number, name: string, props: { payload: { time: string; value: number } }) => {
              if (setValue && parsedValue !== props.payload.value) {
                setValue(props.payload.value)
              }
              const formattedTime = dayjs(props.payload.time).format("MMM D, 'YY")
              if (setLabel && label !== formattedTime) setLabel(formattedTime)
            }}
          />
          <defs>
            <filter id="filter">
              <feDropShadow dx="0" dy="3" stdDeviation="3" floodColor="#246CF9" />
              <feDropShadow dx="0" dy="-3" stdDeviation="3" floodColor="#246CF9" />
            </filter>
          </defs>
          <Line
            type="linear"
            dot={false}
            dataKey="value"
            stroke="#246CF9"
            filter="url(#filter)"
            animationDuration={300}
          />
        </LineChart>
      </ResponsiveContainer>
      <RowBetween height={'50px'}>
        <LabelAbsoluteLeftBottom>{bottomLeft ?? null}</LabelAbsoluteLeftBottom>
        {bottomRight ?? null}
      </RowBetween>
    </WrapperChart>
  )
}

export default Chart
