import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { PortisConnector } from '@web3-react/portis-connector'

import { FortmaticConnector } from './Fortmatic'
import { NetworkConnector } from './NetworkConnector'

const NETWORK_URL = process.env.REACT_APP_NETWORK_URL
const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID

export const NETWORK_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '25')

if (typeof NETWORK_URL === 'undefined') {
  throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`)
}

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: NETWORK_URL }
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any))
}

export const injected = new InjectedConnector({
  supportedChainIds: [25]
})

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: { 25: NETWORK_URL },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true
  // pollingInterval: 15000
})

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1
})

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1]
})

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: NETWORK_URL,
  appName: 'Uniswap',
  appLogoUrl:
    'https://mpng.pngfly.com/20181202/bex/kisspng-emoji-domain-unicorn-pin-badges-sticker-unicorn-tumblr-emoji-unicorn-iphoneemoji-5c046729264a77.5671679315437924251569.jpg'
})

export const ABOUT_US_URL = process.env.REACT_APP_ABOUT_US_URL ?? ''
export const SITE_URL = process.env.REACT_APP_SITE_URL ?? ''
export const BLOG_URL = process.env.REACT_APP_BLOG_URL ?? '/blog'
export const ABOUT_URL = process.env.REACT_APP_ABOUT_URL ?? '/about'
export const TEAM_URL = process.env.REACT_APP_TEAM_URL ?? '/team'
export const WHITEPAPER_URL = process.env.REACT_APP_WHITEPAPER_URL ?? '/whitepaper'
export const STAKING_URL = process.env.REACT_APP_STAKING_URL ?? '/staking'
export const PRIVACY_URL = process.env.REACT_APP_PRIVACY_URL ?? '/privacy'
export const SUPPORT_MAIL = process.env.REACT_APP_SUPPORT_MAIL ?? ''
export const FACEBOOK_URL = process.env.REACT_APP_FACEBOOK_URL ?? ''
export const TWITTER_URL = process.env.REACT_APP_TWITTER_URL ?? ''
export const INSTAGRAM_URL = process.env.REACT_APP_INSTAGRAM_URL ?? ''
export const TELEGRAM_URL = process.env.REACT_APP_TELEGRAM_URL ?? ''
export const DOCUMENTATION_URL = process.env.REACT_APP_DOCUMENTATION_URL ?? ''
export const DISCORD_URL = process.env.REACT_APP_DISCORD_URL ?? ''
export const ANALYTICS_URL = process.env.REACT_APP_ANALYTICS_URL ?? ''
export const ANYSWAP_BRIDGE_URL = process.env.REACT_APP_ANYSWAP_BRIDGE_URL ?? ''
export const SWAPP_BRIDGE_URL = process.env.REACT_APP_SWAPP_BRIDGE_URL ?? ''
export const LOTTERY_URL = process.env.REACT_APP_LOTTERY_URL ?? ''
export const FARMING_APPLICATION_URL = process.env.REACT_APP_FARMING_APPLICATION_URL ?? ''
export const LIST_ON_DEX_URL = process.env.REACT_APP_LIST_ON_DEX_URL ?? ''

// app routes

export const APP_ANALYTICS_PAGE = '/analytics'
export const APP_FARMING_PAGE = '/farming/pair'
export const APP_ABOUT_US_PAGE = '/about-us'
export const APP_SWAP_PAGE = '/swap'
export const APP_ADD_LIQUIDITY = '/add'
