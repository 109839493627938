import { Currency, ETHER, JSBI, TokenAmount } from '@uniswap/sdk'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'
import { LightCard } from '../../components/Card'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import CurrencyLogo from '../../components/CurrencyLogo'
import { FindPoolTabs } from '../../components/NavigationTabs'
import { MinimalPositionCard } from '../../components/PositionCard'
import Row, { RowBetween} from '../../components/Row'
import CurrencySearchModal from '../../components/SearchModal/CurrencySearchModal'
import { PairState, usePair } from '../../data/Reserves'
import { useActiveWeb3React } from '../../hooks'
import { usePairAdder } from '../../state/user/hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { StyledInternalLink } from '../../theme'
import { currencyId } from '../../utils/currencyId'
import AppBody from '../AppBody'
import { Dots } from '../Pool/styleds'
import FormBlockContainer from '../../components/FormBlockContainer'
import CenterButton from '../../components/CenterButton'
import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'

enum Fields {
  TOKEN0 = 0,
  TOKEN1 = 1
}

export default function PoolFinder() {
  const { account } = useActiveWeb3React()

  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [activeField, setActiveField] = useState<number>(Fields.TOKEN1)

  const [currency0, setCurrency0] = useState<Currency | null>(ETHER)
  const [currency1, setCurrency1] = useState<Currency | null>(null)

  const [pairState, pair] = usePair(currency0 ?? undefined, currency1 ?? undefined)
  const addPair = usePairAdder()
  useEffect(() => {
    if (pair) {
      addPair(pair)
    }
  }, [pair, addPair])

  const validPairNoLiquidity: boolean =
    pairState === PairState.NOT_EXISTS ||
    Boolean(
      pairState === PairState.EXISTS &&
        pair &&
        JSBI.equal(pair.reserve0.raw, JSBI.BigInt(0)) &&
        JSBI.equal(pair.reserve1.raw, JSBI.BigInt(0))
    )

  const position: TokenAmount | undefined = useTokenBalance(account ?? undefined, pair?.liquidityToken)
  const hasPosition = Boolean(position && JSBI.greaterThan(position.raw, JSBI.BigInt(0)))

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      if (activeField === Fields.TOKEN0) {
        setCurrency0(currency)
      } else {
        setCurrency1(currency)
      }
    },
    [activeField]
  )

  const handleSearchDismiss = useCallback(() => {
    setShowSearch(false)
  }, [setShowSearch])
  const StyledLightCard = styled(LightCard)`
    padding: 45px 10px;
    background-color: ${({ theme }) => theme.bg10};
  `
  const prerequisiteMessage = (
    <StyledLightCard>
      <Text textAlign="center">
        {!account ? 'Connect to a wallet to find pools' : 'Select a token to find your liquidity.'}
      </Text>
    </StyledLightCard>
  )

  const CurrencyContainter = styled(FormBlockContainer)`
    padding: 3rem 2rem;
    background-color: ${({ theme }) => theme.bg10};
    width: 50%;
    ${({ theme }) => theme.mediaWidth.upToMedium`
      width: 100%;
      .currencyName{
        max-width: 124px;
      }
    `};

    font-size: 20px;
    font-weight: 500;
    color: ${({ theme }) => theme.text1};
    outline: none;
    cursor: pointer;
    user-select: none;

    :focus,
    :hover {
      transition: linear 0.2s;
      color: ${({ theme }) => theme.primary1};
      path {
        stroke: ${({ theme }) => theme.primary1};
      }
    }
  `

  const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
    margin: 0 0.25rem 0 0.5rem;
    height: 35%;
    path {
      stroke: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
      stroke-width: 1.5px;
    }
  `

  const StyledCenterButton = styled(CenterButton)`
    & > * {
      margin-top: -15px;
    }
  `

  return (
    <AppBody>
      <FindPoolTabs />
      <FormBlockContainer>
        <RowBetween marginBottom={'1rem'}>
          <CurrencyContainter
            onClick={() => {
              setShowSearch(true)
              setActiveField(Fields.TOKEN0)
            }}
          >
            <RowBetween>
              {currency0 ? (
                <Row className={'currencyName'}>
                  <CurrencyLogo currency={currency0} />
                  <Text fontWeight={500} fontSize={20} marginLeft={'12px'}>
                    {currency0.symbol}
                  </Text>
                </Row>
              ) : (
                <Text fontWeight={500} fontSize={20} marginLeft={'12px'}>
                  Select a Token
                </Text>
              )}
              <StyledDropDown selected={!!currency0} />
            </RowBetween>
          </CurrencyContainter>
          <StyledCenterButton />
          <CurrencyContainter
            onClick={() => {
              setShowSearch(true)
              setActiveField(Fields.TOKEN1)
            }}
          >
            <RowBetween>
              {currency1 ? (
                <Row>
                  <CurrencyLogo currency={currency1} />
                  <Text fontWeight={500} fontSize={20} marginLeft={'12px'}>
                    {currency1.symbol}
                  </Text>
                </Row>
              ) : (
                <Text fontWeight={500} fontSize={20} marginLeft={'12px'}>
                  Select a Token
                </Text>
              )}
              <StyledDropDown selected={!!currency1} />
            </RowBetween>
          </CurrencyContainter>
        </RowBetween>

        {hasPosition && (
          <ColumnCenter
            style={{ justifyItems: 'center', backgroundColor: '', padding: '12px 0px', borderRadius: '12px' }}
          >
            <Text textAlign="center" fontWeight={500}>
              Pool Found!
            </Text>
            <StyledInternalLink to={`/pool`}>
              <Text textAlign="center">Manage this pool.</Text>
            </StyledInternalLink>
          </ColumnCenter>
        )}

        {currency0 && currency1 ? (
          pairState === PairState.EXISTS ? (
            hasPosition && pair ? (
              <MinimalPositionCard pair={pair} border="1px solid #CED0D9" />
            ) : (
              <StyledLightCard>
                <AutoColumn gap="sm" justify="center">
                  <Text textAlign="center">You don’t have liquidity in this pool yet.</Text>
                  <StyledInternalLink to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}>
                    <Text textAlign="center">Add liquidity.</Text>
                  </StyledInternalLink>
                </AutoColumn>
              </StyledLightCard>
            )
          ) : validPairNoLiquidity ? (
            <StyledLightCard>
              <AutoColumn gap="sm" justify="center">
                <Text textAlign="center">No pool found.</Text>
                <StyledInternalLink to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}>
                  Create pool.
                </StyledInternalLink>
              </AutoColumn>
            </StyledLightCard>
          ) : pairState === PairState.INVALID ? (
            <StyledLightCard>
              <AutoColumn gap="sm" justify="center">
                <Text textAlign="center" fontWeight={500}>
                  Invalid pair.
                </Text>
              </AutoColumn>
            </StyledLightCard>
          ) : pairState === PairState.LOADING ? (
            <StyledLightCard>
              <AutoColumn gap="sm" justify="center">
                <Text textAlign="center">
                  Loading
                  <Dots />
                </Text>
              </AutoColumn>
            </StyledLightCard>
          ) : null
        ) : (
          prerequisiteMessage
        )}
        <CurrencySearchModal
          isOpen={showSearch}
          onCurrencySelect={handleCurrencySelect}
          onDismiss={handleSearchDismiss}
          showCommonBases
          selectedCurrency={(activeField === Fields.TOKEN0 ? currency1 : currency0) ?? undefined}
        />
      </FormBlockContainer>
    </AppBody>
  )
}
