const ProtocolData = {
  volumeUSD: 3080275604.7424316,
  volumeUSDChange: 23.291675953709778,
  tvlUSD: 4339073643.046312,
  tvlUSDChange: 0.13778735399240175,
  feesUSD: 4957469.839884996,
  feeChange: 10.959162262050889,
  txCount: 40911,
  txCountChange: -7.652197467325794
}

export default ProtocolData
