import { Currency } from '@uniswap/sdk'
import React from 'react'
import styled from 'styled-components'
import CurrencyLogo from '../CurrencyLogo'
import { LOGOS } from '../../constants'

const Wrapper = styled.div<{ margin: boolean; sizeraw: number }>`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-right: ${({ sizeraw, margin }) => margin && (sizeraw / 3 + 8).toString() + 'px'};
`

interface DoubleCurrencyLogoProps {
  margin?: boolean
  size?: number
  currency0?: Currency
  currency1?: Currency
}

const HigherLogo = styled(CurrencyLogo)`
  z-index: 2;
`
const CoveredLogo = styled(CurrencyLogo)<{ sizeraw: number }>`
  position: absolute;
  left: ${({ sizeraw }) => '-' + (sizeraw / 2).toString() + 'px'} !important;
`

export function DoubleCurrencyLogo({ currency0, currency1, size = 16, margin = false }: DoubleCurrencyLogoProps) {
  return (
    <Wrapper sizeraw={size} margin={margin}>
      {currency0 && <HigherLogo currency={currency0} size={size.toString() + 'px'} />}
      {currency1 && <CoveredLogo currency={currency1} size={size.toString() + 'px'} sizeraw={size} />}
    </Wrapper>
  )
}

interface DoubleProps {
  margin?: boolean
  size?: number
  name0?: string
  name1?: string
}

const DoubleLogoWrapper = styled.span<{ margin: boolean; sizeraw: number }>`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-right: ${({ sizeraw, margin }) => margin && (sizeraw / 3 + 8).toString() + 'px'};
  @media screen and (max-width: 500px) {
    display: none !important;
  }
`

const Higher = styled.span<{ size: number }>`
  margin-left: ${({ size }) => size.toString() + 'px'};
  z-index: 2;

`
const Covered = styled.span<{ size: number }>`
  position: absolute;
  right: ${({ size }) => size.toString() + 'px'} !important;
`

export const getLogo = (tokenName?: string) => {
  const logo = LOGOS.find(item => item.name === tokenName)?.url
  if (logo) {
    return (
      <img
        src={logo}
        alt="logo"
        style={{
          // marginRight: 8,
          width: 32,
          borderRadius: '50%',
          backgroundColor: '#272B30'
        }}
      />
    )
  }
  return false
}

export function DoubleLogo({ name0, name1, size = 20, margin = false }: DoubleProps) {
  const logo0 = getLogo(name0)
  const logo1 = getLogo(name1)
  return (
    <DoubleLogoWrapper sizeraw={size / 2} margin={true}>
      {logo0 && <Covered size={size}>{logo0}</Covered>}
      {logo1 && <Higher size={size}>{logo1}</Higher>}
    </DoubleLogoWrapper>
  )
}
