import React, { useEffect } from 'react'
import exchanger from '../../assets/images/exchanger.png'
import rocket from '../../assets/images/rocket.png'
import candle from '../../assets/images/Candle.png'
import { APP_SWAP_PAGE, APP_ADD_LIQUIDITY } from '../../connectors'
import { RouteComponentProps } from 'react-router'
import { ReactComponent as UniswapPartner } from '../../assets/svg/partners/uniswap.svg'
import { ReactComponent as ZerionPartner } from '../../assets/svg/partners/zerion.svg'
import { ReactComponent as PancakePartner } from '../../assets/svg/partners/pancake.svg'
import { ReactComponent as CertikPartner } from '../../assets/svg/partners/certik.svg'
import { ReactComponent as CoinMarketPartner } from '../../assets/svg/partners/coinMarket.svg'
import { useSelectBackgroundUrl } from '../../state/application/hooks'
import {
  WrapperAboutUs,
  FirstBlock,
  Title,
  TitleDescription,
  ButtonsGroup,
  CustomButtonFill,
  CustomButtonOutline,
  ExchangeImgWrapper,
  SecondBlock,
  PartnersWrapper,
  SItemFirst,
  SItemFirstInfo,
  SItemInfoTitle,
  SItemFirstInfoDescription,
  SItemFirstImgWrapper,
  SItemSecond,
  SItemFirstInfoButton
} from './AboutUs.styled.components'

export const AboutUsPage = ({ history }: RouteComponentProps) => {
  const setUrlNone = useSelectBackgroundUrl('none')
  const setUrlImg = useSelectBackgroundUrl('./background.png')

  useEffect(() => {
    setUrlNone()
    window.scrollTo(0, 0)
    return () => setUrlImg()
  }, [setUrlImg, setUrlNone])

  return (
    <WrapperAboutUs>
      <FirstBlock>
        <Title>CryptoSwapp on Cronos</Title>
        <TitleDescription>Let’s Make Dex’s Great Again.</TitleDescription>
        <ButtonsGroup>
          <CustomButtonFill onClick={() => history.push(APP_SWAP_PAGE)}>Exchange</CustomButtonFill>
          <CustomButtonOutline onClick={() => history.push(APP_ADD_LIQUIDITY)}>Liquidity</CustomButtonOutline>
        </ButtonsGroup>
        <ExchangeImgWrapper>
          <img src={exchanger} alt={'exchanger'} />
        </ExchangeImgWrapper>
      </FirstBlock>
      <SecondBlock>
        <PartnersWrapper>
          <UniswapPartner />
          <ZerionPartner />
          <PancakePartner />
          <CertikPartner />
          <CoinMarketPartner />
        </PartnersWrapper>
        <SItemFirst>
          <SItemFirstInfo>
            <SItemInfoTitle>The Leading Dex on the Cronos Chain.</SItemInfoTitle>
            <SItemFirstInfoDescription>Made by the leading crypto experts for the pepople</SItemFirstInfoDescription>
            <SItemFirstInfoButton onClick={() => history.push(APP_SWAP_PAGE)}>Exchange</SItemFirstInfoButton>
          </SItemFirstInfo>
          <SItemFirstImgWrapper>
            <img src={rocket} alt={'rocket'} />
          </SItemFirstImgWrapper>
        </SItemFirst>
        <SItemSecond>
          <SItemFirstImgWrapper>
            <img src={candle} alt={'candle'} />
          </SItemFirstImgWrapper>
          <SItemFirstInfo>
            <SItemInfoTitle position={'end'}>The Highest Security Standards in the Industry.</SItemInfoTitle>
            <SItemFirstInfoDescription>Pending audits by Certik and deployed on Skynet. </SItemFirstInfoDescription>
            <SItemFirstInfoButton onClick={() => history.push(APP_ADD_LIQUIDITY)}>Liquidity</SItemFirstInfoButton>
          </SItemFirstInfo>
        </SItemSecond>
      </SecondBlock>
    </WrapperAboutUs>
  )
}
