import React from 'react'
import { ChainId, TokenAmount, ETHER } from '@uniswap/sdk'
import { Text } from 'rebass'
import styled from 'styled-components'

import { useActiveWeb3React } from '../../hooks'
import CurrencyLogo from '../CurrencyLogo'
import { useETHBalances, useAggregateUniBalance } from '../../state/wallet/hooks'
import { CountUp } from 'use-count-up'
import { TYPE } from '../../theme'
import { SWAPP } from '../../constants'
import usePrevious from '../../hooks/usePrevious'

import { useUserHasAvailableClaim } from '../../state/claim/hooks'

const AccountElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  padding: 4px 8px;
  height: 36px;
  margin: 5px;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-right: 0px;
    font-size: 11px;
    height: 25px;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 11px;
  `};
`

const LogoWrapper = styled.div`
  width: 24px;
  height: 24px;
  box-shadow: 0px 6px 10px rgb(0 0 0 / 8%);
  border-radius: 24px;
`

function WalletBalance() {
  const { account, chainId } = useActiveWeb3React()
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const availableClaim: boolean = useUserHasAvailableClaim(account)
  const aggregateBalance: TokenAmount | undefined = useAggregateUniBalance()
  const countUpValue = aggregateBalance?.toFixed(4) ?? '0'
  const countUpValuePrevious = usePrevious(countUpValue) ?? '0'

  return (
    <>
      {!!account && !availableClaim && chainId && (
        <AccountElement style={{ pointerEvents: 'auto' }}>
          <LogoWrapper>
            <CurrencyLogo currency={SWAPP[ChainId.CRONOS]} />
          </LogoWrapper>
          <TYPE.white
            style={{
              paddingLeft: '.4rem',
              paddingRight: '.4rem'
            }}
          >
            <CountUp
              key={countUpValue}
              isCounting
              start={parseFloat(countUpValuePrevious)}
              end={parseFloat(countUpValue)}
              thousandsSeparator={','}
              duration={1}
            />
          </TYPE.white>
          {'SWAPP'}
        </AccountElement>
      )}
      {!!account && (
        <AccountElement style={{ pointerEvents: 'auto' }}>
          {account && userEthBalance ? (
            <LogoWrapper>
              <CurrencyLogo currency={ETHER} />
            </LogoWrapper>
          ) : null}
          {account ? (
            <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
              {userEthBalance ? userEthBalance?.toSignificant(4) : 0} CRO
            </BalanceText>
          ) : null}
        </AccountElement>
      )}
    </>
  )
}

export default WalletBalance
