// @ts-ignore

import { ChainId, JSBI, Percent, Token, WETH } from '@uniswap/sdk'
import { TokenInfo } from '@uniswap/token-lists'
import { AbstractConnector } from '@web3-react/abstract-connector'

// import { fortmatic, injected, portis, walletconnect, walletlink } from '../connectors'
import { injected, walletconnect } from '../connectors'

import ethereumLogo from '../assets/images/ethereum-logo.png'
import cronosLogo from '../assets/images/tokens/crypto-com-logo.png'
import swappLogo from '../assets/images/tokens/swapp-logo.png'
import usdtLogo from '../assets/images/tokens/usdt-logo.png'
import usdcLogo from '../assets/images/tokens/usdc-logo.png'
import wbtcLogo from '../assets/images/tokens/wbtc-logo.png'
import daiLogo from '../assets/images/tokens/dai-logo.png'
import wethLogo from '../assets/images/tokens/weth-logo.png'
import cHIBIKILogo from '../assets/images/tokens/cHIBIKI-logo.png'
import crocroLogo from '../assets/images/tokens/crocro-logo.jpg'
import cpyLogo from '../assets/images/tokens/cpy-logo.jpg'
import lootLogo from '../assets/images/tokens/loot-logo.svg'
import ftmLogo from '../assets/images/tokens/ftm-logo.png'
import busdLogo from '../assets/images/tokens/busd-logo.png'
import vvsLogo from '../assets/images/tokens/vvs-logo.svg'
import crxLogo from '../assets/images/tokens/crx-logo.svg'
import cronaLogo from '../assets/images/tokens/crona-logo.svg'

export const ROUTER_ADDRESS = '0x600d0b65C2A25b64C9b517A43B7a44592448d285'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const CURRENT_REWARDS_ROUND = 2

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const DAI = new Token(ChainId.CRONOS, '0xF2001B145b43032AAF5Ee2884e456CCd805F677D', 18, 'DAI', 'Dai Stablecoin')
export const USDC = new Token(ChainId.CRONOS, '0xc21223249CA28397B4B6541dfFaEcC539BfF0c59', 6, 'USDC', 'USDC')
export const USDT = new Token(ChainId.CRONOS, '0x66e428c3f67a68878562e79A0234c1F83c208770', 6, 'USDT', 'Tether USD')
export const WBTC = new Token(ChainId.CRONOS, '0x062E66477Faf219F25D27dCED647BF57C3107d52', 18, 'WBTC', 'Wrapped BTC')
export const WETH_ = new Token(ChainId.CRONOS, '0xe44Fd7fCb2b1581822D0c862B68222998a0c299a', 18, 'WETH', 'Wrapped ETH')
export const Hibiki = new Token(
  ChainId.CRONOS,
  '0x6B66fCB66Dba37F99876a15303b759c73fc54ed0',
  18,
  'cHIBIKI',
  'Hibiki.finance'
)

export const BUSD = new Token(ChainId.CRONOS, '0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8', 18, 'BUSD', 'BUSD')
export const FTM = new Token(ChainId.CRONOS, '0xB44a9B6905aF7c801311e8F4E76932ee959c663C', 18, 'FTM', 'Fantom')
export const VVS = new Token(ChainId.CRONOS, '0x2D03bECE6747ADC00E1a131BBA1469C15fD11e03', 18, 'VVS', 'VVSToken')
export const CRONA = new Token(
  ChainId.CRONOS,
  '0xadbd1231fb360047525BEdF962581F3eee7b49fe',
  18,
  'CRONA',
  'CronaSwap Token'
)
export const CRX = new Token(ChainId.CRONOS, '0xe243CCab9E66E6cF1215376980811ddf1eb7F689', 18, 'CRX', 'Crodex Token')

// TODO this is only approximate, it's actually based on blocks
export const PROPOSAL_LENGTH_IN_DAYS = 7

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.CRONOS]],
  [ChainId.ROPSTEN]: [WETH[ChainId.CRONOS]],
  [ChainId.KOVAN]: [WETH[ChainId.CRONOS]],
  [ChainId.RINKEBY]: [WETH[ChainId.CRONOS]],
  [ChainId.GÖRLI]: [WETH[ChainId.CRONOS]],
  [ChainId.CASSINI]: [WETH[ChainId.CRONOS]],
  [ChainId.CRONOS]: [WETH[ChainId.CRONOS]]
}

export const SWAPP_ADDRESS = {
  [ChainId.CASSINI]: '0x1a9b42fF571D221368E76E3396E0582Ab6FD0eB7',
  [ChainId.CRONOS]: '0x245a551ee0F55005e510B239c917fA34b41B3461'
}
export const SWAPP: { [chainId: number]: Token } = {
  [ChainId.CASSINI]: new Token(ChainId.CASSINI, SWAPP_ADDRESS[ChainId.CASSINI], 18, 'SWAPP', 'SWAPP token'),
  [ChainId.CRONOS]: new Token(ChainId.CRONOS, SWAPP_ADDRESS[ChainId.CRONOS], 18, 'SWAPP', 'SWAPP token')
}

export const SWAPP_TOKEN_LIST: TokenInfo[] = Object.values(SWAPP).map(token => {
  return { ...token, ...{ logoURI: '', name: token.name ?? '', symbol: token.symbol ?? '' } }
})

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.CRONOS]: [...WETH_ONLY[ChainId.CRONOS], DAI, USDC, USDT, WBTC, WETH_]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.CRONOS]: {
    // [AMPL.address]: [DAI, WETH[ChainId.CRONOS]]
  }
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.CRONOS]: [...WETH_ONLY[ChainId.CRONOS], WETH_, WBTC, DAI, USDC, USDT, SWAPP[ChainId.CRONOS]]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.CRONOS]: [...WETH_ONLY[ChainId.CRONOS], DAI, USDC, USDT]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.CRONOS]: [
    [
      new Token(ChainId.CRONOS, '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643', 8, 'cDAI', 'Compound Dai'),
      new Token(ChainId.CRONOS, '0x39AA39c021dfbaE8faC545936693aC917d5E7563', 8, 'cUSDC', 'Compound USD Coin')
    ],
    [USDC, USDT],
    [DAI, USDT]
  ]
}

export const TOKEN_HIDE = ['0xd46ba6d942050d489dbd938a2c909a5d5039a161', '0x7dfb72a2aad08c937706f21421b15bfc34cba9ca']
export const POOL_HIDE = [
  '0x86d257cdb7bc9c0df10e84c8709697f92770b335',
  '0xf8dbd52488978a79dfe6ffbd81a01fc5948bf9ee',
  '0x8fe8d9bb8eeba3ed688069c3d6b556c9ca258248',
]

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true
  }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))

export const LOGOS = [
  {
    name: 'ETH',
    url: ethereumLogo
  },
  {
    name: 'CRO',
    url: cronosLogo
  },
  {
    name: 'Wrapped CRO',
    url: cronosLogo
  },
  {
    name: 'SWAPP token',
    url: swappLogo
  },
  {
    name: 'Tether USD',
    url: usdtLogo
  },
  {
    name: 'USDC',
    url: usdcLogo
  },
  {
    name: 'USD Coin',
    url: usdcLogo
  },
  {
    name: 'Wrapped BTC',
    url: wbtcLogo
  },
  {
    name: 'Dai Stablecoin',
    url: daiLogo
  },
  {
    name: 'Wrapped ETH',
    url: wethLogo
  },
  {
    name: 'Wrapped Ether',
    url: wethLogo
  },
  {
    name: 'Hibiki.finance',
    url: cHIBIKILogo
  },
  {
    name: 'CrO cRo',
    url: crocroLogo
  },
  {
    name: 'CronosPunkYield',
    url: cpyLogo
  },
  {
    name: 'Loot Network',
    url: lootLogo
  },
  {
    name: 'Fantom',
    url: ftmLogo
  },
  {
    name: 'BUSD',
    url: busdLogo
  },
  {
    name: 'VVSToken',
    url: vvsLogo
  },
  {
    name: 'Crodex Token',
    url: crxLogo
  },
  {
    name: 'CronaSwap Token',
    url: cronaLogo
  }
]
