export interface TokenItem {
  name: string
  symbol: string
}

export interface SwappItem {
  token0: TokenItem
  token1: TokenItem
  rewards: number
  changesDaily: number
  changesMonthly: number
  changesYearly: number
  baseReserve: number
  quoteReserve: number
  tvl: number
}

export const cryptoSwappMock = [
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'Tether USD',
      symbol: 'USDT'
    },
    rewards: 0.14,
    changesDaily: 0.05,
    changesMonthly: 4.23,
    changesYearly: -4.2,
    baseReserve: 89390000,
    quoteReserve: 902700,
    tvl: 177287124.14
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'CRO',
      symbol: 'CRO'
    },
    rewards: 0.15,
    changesDaily: -0.08,
    changesMonthly: 2.12,
    changesYearly: 4.2,
    baseReserve: 72390000,
    quoteReserve: 802500,
    tvl: 200287124.14
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'Wrapped CRO',
      symbol: 'WCRO'
    },
    rewards: 0.14,
    changesDaily: 0.05,
    changesMonthly: 4.23,
    changesYearly: -4.2,
    baseReserve: 89390000,
    quoteReserve: 902700,
    tvl: 177287124.14
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'USD//C',
      symbol: 'USDC'
    },
    rewards: 0.05,
    changesDaily: 0.08,
    changesMonthly: -3.5,
    changesYearly: 1.2,
    baseReserve: 91390000,
    quoteReserve: 592500,
    tvl: 135287124.14
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'Wrapped BTC',
      symbol: 'WBTC'
    },
    rewards: 0.08,
    changesDaily: 0.11,
    changesMonthly: -3.11,
    changesYearly: 2.8,
    baseReserve: 69330000,
    quoteReserve: 712400,
    tvl: 230287124.14
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'Dai Stablecoin',
      symbol: 'DAI'
    },
    rewards: 0.07,
    changesDaily: 0.04,
    changesMonthly: -1.23,
    changesYearly: -3.1,
    baseReserve: 83390000,
    quoteReserve: 722500,
    tvl: 177287222.14
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'Wrapped ETH',
      symbol: 'WETH'
    },
    rewards: 0.08,
    changesDaily: 0.02,
    changesMonthly: -2.21,
    changesYearly: 2.1,
    baseReserve: 93390002,
    quoteReserve: 772700,
    tvl: 174287234.14
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'Hibiki.finance',
      symbol: 'cHIBIKI'
    },
    rewards: 0.12,
    changesDaily: 0.04,
    changesMonthly: 1.12,
    changesYearly: -3.2,
    baseReserve: 84590000,
    quoteReserve: 722500,
    tvl: 310287124.14
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'CronosPunkYield',
      symbol: 'CPY'
    },
    rewards: 0.21,
    changesDaily: -1.05,
    changesMonthly: 1.23,
    changesYearly: 3.2,
    baseReserve: 92290000,
    quoteReserve: 745700,
    tvl: 210287111.14
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'ETH',
      symbol: 'ETH'
    },
    rewards: 0.03,
    changesDaily: 1.4,
    changesMonthly: -2.02,
    changesYearly: 1.2,
    baseReserve: 55590000,
    quoteReserve: 405500,
    tvl: 122287124.14
  }
]
