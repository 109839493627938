import React from 'react'
import { TopSwappLiquidity } from '../../constants/mocks/topSwappLiquidity'
import useTheme from '../../hooks/useTheme'
import Loader, { LoadingRows } from '../Loader'
import styled from 'styled-components'
import { DarkGreyCard } from '../Card'
import { AutoColumn } from '../Column'
import {
  AnalyticsRowLabel,
  AnalyticsTableClickableText,
  Badge,
  AnalyticsRowLabelDiv
} from '../CryptoSwappTable/components'
import { DoubleLogo } from '../DoubleLogo'
import { formatDollar } from '../../utils/numbers'
import { Break, PageButtons } from '../shared'
import { TableLeftButton, TableRightButton } from '../Arrows/TableArrows'
import { TableLineChart } from './components'
import { useTableSort } from '../../hooks/useTableSort'

interface TopSwappLiquidityTableProps {
  data: TopSwappLiquidity[]
  maxItems?: number
}

const Wrapper = styled(DarkGreyCard)`
  width: 100%;
`

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;

  grid-template-columns: 1.2fr repeat(3, 0.8fr) repeat(2, 0.5fr) 1.5fr;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1.2fr repeat(3, 1fr) repeat(2, 0.8fr);
    & > :nth-child(7) {
      display: none;
    }
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: 1.2fr repeat(2, 1fr) repeat(2, 0.8fr);
    & > :nth-child(3) {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 1.2fr repeat(2, 1fr);
    & > :nth-child(5) {
      display: none;
    }
    & :nth-child(6) {
      display: none;
    }
    & :nth-child(4) {
      justify-content: flex-end;
    }
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    & > :nth-child(4) {
      display: none;
    }
    & :nth-child(2) {
      justify-content: flex-end;
    }
  }
`

const DataRow = ({ rowData, index }: { rowData: TopSwappLiquidity; index: number }) => {
  const theme = useTheme()
  return (
    <ResponsiveGrid>
      <AnalyticsRowLabel color={theme.primary1}>
        <DoubleLogo name0={rowData.token0.name} name1={rowData.token1.name} />
        {rowData.token0.symbol}-{rowData.token1.symbol}
      </AnalyticsRowLabel>
      <AnalyticsRowLabel fontWeight={700}>{formatDollar(rowData.liquidity)}</AnalyticsRowLabel>
      <AnalyticsRowLabel fontWeight={700}>{formatDollar(rowData.volume24h)}</AnalyticsRowLabel>
      <AnalyticsRowLabel fontWeight={700}>{formatDollar(rowData.price)}</AnalyticsRowLabel>
      <AnalyticsRowLabel position={'flex-start'}>
        <Badge color={rowData.fees24h < 0 ? theme.red3 : theme.green3}>{rowData.fees24h}%</Badge>
      </AnalyticsRowLabel>
      <AnalyticsRowLabel position={'flex-start'}>
        <Badge color={rowData.fees7d < 0 ? theme.red3 : theme.green3}>{rowData.fees7d}%</Badge>
      </AnalyticsRowLabel>
      <AnalyticsRowLabelDiv fontWeight={700}>
        <TableLineChart data={rowData.graph7d} color={rowData.fees7d < 0 ? theme.red3 : theme.green3} />
      </AnalyticsRowLabelDiv>
    </ResponsiveGrid>
  )
}

const SORT_FIELD = {
  liquidity: 'liquidity',
  volume24h: 'volume24h',
  price: 'price',
  fees24h: 'fees24h',
  fees7d: 'fees7d'
}

const MAX_ITEMS = 9

export const TopSwappLiquidityTable: React.FC<TopSwappLiquidityTableProps> = ({ data, maxItems = MAX_ITEMS }) => {
  // theming
  const theme = useTheme()
  const { setPage, maxPage, sortedData, handleSort, arrow, page } = useTableSort<TopSwappLiquidity>({
    rows: data,
    maxItems,
    sortName: SORT_FIELD.liquidity
  })

  if (!data) {
    return <Loader />
  }
  return (
    <Wrapper>
      {data.length > 0 ? (
        <AutoColumn>
          <ResponsiveGrid>
            <AnalyticsTableClickableText color={theme.text2}>Name</AnalyticsTableClickableText>
            <AnalyticsTableClickableText color={theme.text2} onClick={() => handleSort(SORT_FIELD.liquidity)}>
              Liquidity {arrow(SORT_FIELD.liquidity)}
            </AnalyticsTableClickableText>
            <AnalyticsTableClickableText color={theme.text2} onClick={() => handleSort(SORT_FIELD.volume24h)}>
              Volume (24h) {arrow(SORT_FIELD.volume24h)}
            </AnalyticsTableClickableText>
            <AnalyticsTableClickableText color={theme.text2} onClick={() => handleSort(SORT_FIELD.price)}>
              Price {arrow(SORT_FIELD.price)}
            </AnalyticsTableClickableText>
            <AnalyticsTableClickableText color={theme.text2} onClick={() => handleSort(SORT_FIELD.fees24h)}>
              24h {arrow(SORT_FIELD.fees24h)}
            </AnalyticsTableClickableText>
            <AnalyticsTableClickableText color={theme.text2} onClick={() => handleSort(SORT_FIELD.fees7d)}>
              7d {arrow(SORT_FIELD.fees7d)}
            </AnalyticsTableClickableText>
            <AnalyticsTableClickableText color={theme.text2} position={'flex-end'}>
              Last 7 days
            </AnalyticsTableClickableText>
          </ResponsiveGrid>
          <Break />
          {sortedData.map((data, i) => {
            if (data) {
              return (
                <React.Fragment key={i}>
                  <DataRow index={(page - 1) * MAX_ITEMS + i} rowData={data} />
                  <Break />
                </React.Fragment>
              )
            }
            return null
          })}
          <PageButtons
            style={{
              marginTop: '30px'
            }}
          >
            <div
              onClick={() => {
                setPage(page === 1 ? page : page - 1)
              }}
            >
              <TableLeftButton faded={page === 1} />
            </div>
            <div
              onClick={() => {
                setPage(page === maxPage ? page : page + 1)
              }}
            >
              <TableRightButton faded={page === maxPage} />
            </div>
          </PageButtons>
        </AutoColumn>
      ) : (
        <LoadingRows>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </LoadingRows>
      )}
    </Wrapper>
  )
}
