import React from 'react'
import styled from 'styled-components'
import { ReactComponent as DefaultRightArrow } from '../../assets/svg/tables/rightArrow.svg'

const TableButtonWrapper = styled.div<{ faded: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #272b30;
  background: #1a1d1f;
  opacity: ${props => (props.faded ? 0.3 : 1)};
  cursor: ${props => (props.faded ? 'not-allowed' : 'pointer')};
  :hover {
    opacity: ${props => (props.faded ? 0.3 : 0.8)};
  }
`
interface ArrowProps {
  faded: boolean
}
export const TableRightButton: React.FC<ArrowProps> = ({ faded }) => {
  return (
    <TableButtonWrapper faded={faded}>
      <DefaultRightArrow />
    </TableButtonWrapper>
  )
}

export const TableLeftButton: React.FC<ArrowProps> = ({ faded }) => {
  return (
    <TableButtonWrapper faded={faded}>
      <DefaultRightArrow
        style={{
          transform: 'rotate(180deg)'
        }}
      />
    </TableButtonWrapper>
  )
}
