import React from 'react'
import styled from 'styled-components'
import { NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Row from '../../components/Row'
import { useDarkModeManager } from '../../state/user/hooks'
import { ExternalLink, CloseIcon } from '../../theme'
import {
  SWAPP_BRIDGE_URL,
  LOTTERY_URL,
  ANALYTICS_URL,
  // APP_ANALYTICS_PAGE,
  APP_ABOUT_US_PAGE,
  APP_FARMING_PAGE
} from '../../connectors'
import WalletBalance from '../AccountDetails/wallets'
import Logo from '../../assets/svg/logo.svg'
import LogoDark from '../../assets/svg/logo.svg'
import { ReactComponent as AboutUs } from '../../assets/svg/menu/about-us.svg'
import { ReactComponent as Exchange } from '../../assets/svg/menu/exchange.svg'
import { ReactComponent as Liquidity } from '../../assets/svg/menu/liquidity.svg'
import { ReactComponent as Bridge } from '../../assets/svg/menu/bridge.svg'
import { ReactComponent as Analytics } from '../../assets/svg/menu/analytics.svg'
import { ReactComponent as Farming } from '../../assets/svg/menu/farming.svg'
import { ReactComponent as Lottery } from '../../assets/svg/menu/lottery.svg'

const HeaderLinks = styled(Row)`
  margin-top: 50px;
  justify-content: start;
  align-items: baseline;
  flex-direction: column;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255,255,255,0.08);
    margin-bottom: 20px;
  `};
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  border-radius: 12px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  width: 100%;
  align-items: center;
  font-weight: 600;
  height: 48px;
  padding: 14px;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #6f767e;
  margin-bottom: 15px;

  svg {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    stroke: ${({ theme }) => theme.chartLabel1};
  }

  &.${activeClassName} {
    position: relative;
    color: #1aa9d6;
    background: rgba(255, 255, 255, 0.08);
    :hover,
    :focus {
      text-decoration: none;
      cursor: default;
    }
    & svg path,
    svg circle {
      stroke: ${({ theme }) => theme.secondary1};
    }
  }

  :hover,
  :focus {
    color: #1aa9d6;
    background: rgba(255, 255, 255, 0.08);
    & svg path,
    svg circle {
      stroke: ${({ theme }) => theme.secondary1};
    }
  }

  img {
    margin-right: 12px;
  }
`

const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName
})<{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  border-radius: 12px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  font-size: 1rem;
  width: 100%;
  align-items: center;
  font-weight: 600;
  height: 48px;
  padding: 14px;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #6f767e;
  margin-bottom: 15px;

  svg {
    margin-right: 12px;
    stroke: ${({ theme }) => theme.chartLabel1};
  }

  &.${activeClassName} {
    color: #1aa9d6;
    & svg {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & svg path,
    svg circle {
      stroke: ${({ theme }) => theme.secondary1};
    }
  }

  :hover {
    color: #1aa9d6;
    background: rgba(255, 255, 255, 0.08);
    & svg path,
    svg circle {
      stroke: ${({ theme }) => theme.secondary1};
    }
  }

  img {
    margin-right: 12px;
  }
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    /*transform: rotate(-5deg);*/
  }
`

const StyledClose = styled(CloseIcon)`
  position: absolute;
  right: 16px;
  top: 16px;
  display: none;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    display: inherit;
  `};
`

const MenuWrapper = styled.div<{ isMenuVisible: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 285px;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  background: rgba(24, 26, 28, 0.5);
  backdrop-filter: blur(166px);
  z-index: 4;
  transition: 0.3s;

  ${({ theme, isMenuVisible }) => theme.mediaWidth.upToLarge`
    left: ${() => (isMenuVisible ? '0px' : '-400px')}
  `};
`

const WalletElement = styled.div`
  display: none;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: flex;
  `};
`

interface SiderMenuProps {
  isMenuVisible: boolean
  handleMenuVisibility: Function
}

export default function SideMenu({ isMenuVisible, handleMenuVisibility }: SiderMenuProps) {
  const location = useLocation()
  const [isDark] = useDarkModeManager()
  const { t } = useTranslation()
  return (
    <MenuWrapper isMenuVisible={isMenuVisible}>
      <StyledClose stroke="white" onClick={() => handleMenuVisibility(false)} />
      <Title href="." style={{}}>
        <UniIcon>
          <img height={'30px'} src={isDark ? LogoDark : Logo} alt="logo" />
        </UniIcon>
      </Title>
      <HeaderLinks>
        <StyledNavLink
          id={`about-us-nav-link`}
          to={APP_ABOUT_US_PAGE}
          isActive={() => location.pathname.includes(APP_ABOUT_US_PAGE)}
        >
          <AboutUs />
          {t('About Us')}
        </StyledNavLink>
        <StyledNavLink id={`swap-nav-link`} to={'/swap'} isActive={() => location.pathname.includes('/swap')}>
          <Exchange />
          {t('Exchange')}
        </StyledNavLink>
        <StyledNavLink
          id={`pool-nav-link`}
          to={'/pool'}
          isActive={() =>
            location.pathname.includes('/pool') ||
            location.pathname.includes('/add') ||
            location.pathname.includes('/remove')
          }
        >
          <Liquidity />
          {t('Liquidity')}
        </StyledNavLink>
        <StyledNavLink
          id={`farming-nav-link`}
          to={APP_FARMING_PAGE}
          isActive={() => location.pathname.includes(APP_FARMING_PAGE)}
        >
          <Farming />
          {t('Farming')}
        </StyledNavLink>
        {/*<StyledNavLink*/}
        {/*  id={`analytics-nav-link`}*/}
        {/*  to={APP_ANALYTICS_PAGE}*/}
        {/*  isActive={() => location.pathname.includes(APP_ANALYTICS_PAGE)}*/}
        {/*>*/}
        {/*  <Analytics />*/}
        {/*  {t('Analytics')}*/}
        {/*</StyledNavLink>*/}
        <StyledExternalLink id={`analytics-nav-link`} href={ANALYTICS_URL}>
          <Analytics />
          {t('Analytics')}
        </StyledExternalLink>
        <StyledExternalLink id={`anyswap-bridge-nav-link`} href={SWAPP_BRIDGE_URL}>
          <Bridge />
          {t('Swapp Bridge')}
        </StyledExternalLink>
        <StyledNavLink
          id={`swap-nav-link`}
          to={'/anyswap-bridge'}
          isActive={() => location.pathname.includes('/anyswap-bridge')}
        >
          <Bridge />
          {t('Anyswap Bridge')}
        </StyledNavLink>
        <StyledExternalLink id={`anyswap-lottery-nav-link`} href={LOTTERY_URL}>
          <Lottery style={{ marginTop: '5px' }} />
          {t('LotteryX')}
        </StyledExternalLink>
      </HeaderLinks>
      <WalletElement>
        <WalletBalance />
      </WalletElement>
    </MenuWrapper>
  )
}
