import styled from 'styled-components'

export const AnalyticsRowLabel = styled.p<{
  position?: string
  color?: string
  fontWeight?: number
}>`
  display: flex;
  margin: 0;
  font-size: 12px;
  height: 72px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 600)};
  color: ${({ color }) => color ?? color};
  justify-content: ${({ position }) => (position ? position : 'flex-start')};
  align-items: center;
  font-variant-numeric: tabular-nums;
  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
`

export const AnalyticsRowLabelDiv = styled.div<{
  position?: string
  color?: string
  fontWeight?: number
}>`
  display: flex;
  margin: 0;
  font-size: 12px;
  height: 72px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 600)};
  color: ${({ color }) => color ?? color};
  justify-content: ${({ position }) => (position ? position : 'flex-start')};
  align-items: center;
  font-variant-numeric: tabular-nums;
  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
`

export const AnalyticsTableLabel = styled.p<{ position?: string }>`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.chartLabel1};
  justify-content: ${({ position }) => (position ? position : 'flex-start')};
  align-items: center;
  font-variant-numeric: tabular-nums;
  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
`

export const AnalyticsTableClickableText = styled(AnalyticsTableLabel)`
  text-align: end;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  user-select: none;
  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
`

export const Badge = styled.span<{ color: string }>`
  width: 53px;
  height: 24px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ color }) => color};
`
