import React from 'react'
import { LiquidityPairs } from '../../constants/mocks/liquidityPairs'
import useTheme from '../../hooks/useTheme'
import Loader, { LoadingRows } from '../Loader'
import styled from 'styled-components'
import { DarkGreyCard } from '../Card'
import { AutoColumn } from '../Column'
import { AnalyticsRowLabel, AnalyticsTableClickableText, Badge } from '../CryptoSwappTable/components'
import { DoubleLogo } from '../DoubleLogo'
import { formatDollar } from '../../utils/numbers'
import { Break, PageButtons } from '../shared'
import { TableLeftButton, TableRightButton } from '../Arrows/TableArrows'
import { useTableSort } from '../../hooks/useTableSort'

interface LiquidityPairsTableProps {
  data: LiquidityPairs[]
  maxItems?: number
}

const SORT_FIELD = {
  liquidity: 'liquidity',
  volume24h: 'volume24h',
  volume7d: 'volume7d',
  fees24h: 'fees24h',
  fees7d: 'fees7d',
  feesYearly: 'feesYearly'
}

const Wrapper = styled(DarkGreyCard)`
  width: 100%;
`

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;

  grid-template-columns: 1.2fr repeat(5, 0.8fr) 0.8fr;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(5, 1fr);
    & > :nth-child(3) {
      display: none;
    }
    & > :nth-child(5) {
      display: none;
    }
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
    & > :nth-child(3) {
      display: none;
    }
    & > :nth-child(4) {
      display: none;
    }
    & > :nth-child(6) {
      display: none;
    }
  }

  @media screen and (max-width: 670px) {
    grid-template-columns: repeat(2, 1fr);
    & > :nth-child(2) {
      justify-content: flex-end;
    }
    & :nth-child(7) {
      display: none;
    }
  }
`

const DataRow = ({ rowData, index }: { rowData: LiquidityPairs; index: number }) => {
  const theme = useTheme()
  return (
    <ResponsiveGrid>
      <AnalyticsRowLabel color={theme.primary1}>
        <DoubleLogo name0={rowData.token0.name} name1={rowData.token1.name} />
        {rowData.token0.symbol}-{rowData.token1.symbol}
      </AnalyticsRowLabel>
      <AnalyticsRowLabel fontWeight={700}>{formatDollar(rowData.liquidity)}</AnalyticsRowLabel>
      <AnalyticsRowLabel fontWeight={700}>{formatDollar(rowData.volume24h)}</AnalyticsRowLabel>
      <AnalyticsRowLabel fontWeight={700}>{formatDollar(rowData.volume7d)}</AnalyticsRowLabel>
      <AnalyticsRowLabel fontWeight={700}>{formatDollar(rowData.fees24h)}</AnalyticsRowLabel>
      <AnalyticsRowLabel fontWeight={700}>{formatDollar(rowData.fees7d)}</AnalyticsRowLabel>
      <AnalyticsRowLabel position={'flex-end'}>
        <Badge color={rowData.feesYearly < 0 ? theme.red3 : theme.green3}>{rowData.feesYearly}%</Badge>
      </AnalyticsRowLabel>
    </ResponsiveGrid>
  )
}

const MAX_ITEMS = 9

export const LiquidityPairsTable: React.FC<LiquidityPairsTableProps> = ({ data, maxItems = MAX_ITEMS }) => {
  // theming
  const theme = useTheme()
  const { setPage, maxPage, sortedData, handleSort, arrow, page } = useTableSort<LiquidityPairs>({
    rows: data,
    maxItems,
    sortName: SORT_FIELD.liquidity
  })
  if (!data) {
    return <Loader />
  }
  return (
    <Wrapper>
      {data.length > 0 ? (
        <AutoColumn>
          <ResponsiveGrid>
            <AnalyticsTableClickableText color={theme.text2}>Name</AnalyticsTableClickableText>
            <AnalyticsTableClickableText color={theme.text2} onClick={() => handleSort(SORT_FIELD.liquidity)}>
              Liquidity {arrow(SORT_FIELD.liquidity)}
            </AnalyticsTableClickableText>
            <AnalyticsTableClickableText color={theme.text2} onClick={() => handleSort(SORT_FIELD.volume24h)}>
              Volume (24h) {arrow(SORT_FIELD.volume24h)}
            </AnalyticsTableClickableText>
            <AnalyticsTableClickableText color={theme.text2} onClick={() => handleSort(SORT_FIELD.volume7d)}>
              Volume (7d) {arrow(SORT_FIELD.volume7d)}
            </AnalyticsTableClickableText>
            <AnalyticsTableClickableText color={theme.text2} onClick={() => handleSort(SORT_FIELD.fees24h)}>
              Fees (24h) {arrow(SORT_FIELD.fees24h)}
            </AnalyticsTableClickableText>
            <AnalyticsTableClickableText color={theme.text2} onClick={() => handleSort(SORT_FIELD.fees7d)}>
              Fees (7d) {arrow(SORT_FIELD.fees7d)}
            </AnalyticsTableClickableText>
            <AnalyticsTableClickableText
              color={theme.text2}
              onClick={() => handleSort(SORT_FIELD.feesYearly)}
              position={'flex-end'}
            >
              Fees (Yearly) {arrow(SORT_FIELD.feesYearly)}
            </AnalyticsTableClickableText>
          </ResponsiveGrid>
          <Break />
          {sortedData.map((data, i) => {
            if (data) {
              return (
                <React.Fragment key={i}>
                  <DataRow index={(page - 1) * MAX_ITEMS + i} rowData={data} />
                  <Break />
                </React.Fragment>
              )
            }
            return null
          })}
          <PageButtons
            style={{
              marginTop: '30px'
            }}
          >
            <div
              onClick={() => {
                setPage(page === 1 ? page : page - 1)
              }}
            >
              <TableLeftButton faded={page === 1} />
            </div>
            <div
              onClick={() => {
                setPage(page === maxPage ? page : page + 1)
              }}
            >
              <TableRightButton faded={page === maxPage} />
            </div>
          </PageButtons>
        </AutoColumn>
      ) : (
        <LoadingRows>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </LoadingRows>
      )}
    </Wrapper>
  )
}
