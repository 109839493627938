import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { withRouter } from 'react-router-dom'
import { RowFixed } from '../Row'
import {
  ABOUT_URL,
  BLOG_URL,
  DOCUMENTATION_URL,
  PRIVACY_URL,
  STAKING_URL,
  SUPPORT_MAIL,
  TEAM_URL,
  WHITEPAPER_URL,
  TWITTER_URL,
  FACEBOOK_URL,
  INSTAGRAM_URL,
  TELEGRAM_URL,
  DISCORD_URL,
  FARMING_APPLICATION_URL,
  LIST_ON_DEX_URL
} from '../../connectors'
import { ExternalLink } from '../../theme'
import { useTranslation } from 'react-i18next'
import Logo from '../../assets/svg/logo.svg'
import Discord from '../../assets/svg/social-media/discord.svg'
import Facebook from '../../assets/svg/social-media/facebook.svg'
import Instagram from '../../assets/svg/social-media/instagram.svg'
import Linkedin from '../../assets/svg/social-media/linkedin.svg'
import Medium from '../../assets/svg/social-media/medium.svg'
import Telegram from '../../assets/svg/social-media/telegram.svg'
import Twitter from '../../assets/svg/social-media/twitter.svg'

const FooterContainer = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  width: 100%;
`

const FooterFrame = styled.div`
  display: grid;
  grid-template-columns: 33% 17% 17% 33%;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 18px 48px 18px 48px;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none
  `};
`
const FooterRow = styled(RowFixed)<{ gap?: string; align?: string }>`
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.upToMedium`
   width: 100%;
  `};
  padding-left: 2rem;
`

const FooterLink = styled(ExternalLink)<{ color?: string }>`
  color: ${({ theme, color }) => (color ? color : theme.text6)};
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.text1};
    text-decoration: none;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin: 0 13px;
  `};
`

const FooterItem = styled.h4`
  font-size: 14px;
  font-weight: 300;
  margin: 10px 0;
`

const FooterTitle = styled.h3`
  font-size: 16px;
  margin-top: 10px;
`

const FooterTitleLarge = styled.h5`
  margin-top: 8px;
  font-size: 24px;
  font-weight: 300;
`

const FooterLogo = styled.h3`
  font-size: 24px;
  & > img {
    margin-right: 1rem;
  }
  & > span,
  img {
    display: inline-block;
    vertical-align: middle;
  }
  & > span {
    padding-bottom: 4px;
  }
`

const FooterBottom = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 1rem 80px;
  border-top: solid 1px ${({ theme }) => theme.borderDelimiter};
  font-size: 12px;
  color: ${({ theme }) => theme.text6};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
  `};
`

const SocialIcons = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: space-between;
  flex-flow: row;
  margin-right: 3rem;
  & > * {
    margin: 0 1rem;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
  margin: 0;
    & > * {
      margin: 0px 8px;
    }
  `};
`

const Icon = styled.div<{ size?: number; image?: string }>`
  background-color: ${({ theme, color }) => (color ? color : theme.text6)};
  height: ${({ size }) => (size ? size + 'px' : '32px')};
  width: ${({ size }) => (size ? size + 'px' : '32px')};
  mask: url(${({ image }) => image}) no-repeat center;
  &:hover {
    background-color: ${({ theme, color }) => (color ? color : theme.primary1)};
  }
`

const Copyright = styled.div`
  line-height: 24px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 15px;
    flex: 0 0 100%;
    text-align: center;
  `};
`

function Footer({ history }: { history: any }) {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)
  return (
    <FooterContainer>
      <FooterFrame>
        <FooterRow align={'left'}>
          <FooterLogo>
            <img height={'30px'} src={Logo} alt="logo" />
          </FooterLogo>
          <FooterTitleLarge>Blockchain Your Data</FooterTitleLarge>
        </FooterRow>
        <FooterRow align={'left'}>
          <FooterTitle>{t('Learn')}</FooterTitle>
          <FooterItem>
            <FooterLink href={WHITEPAPER_URL}>{t('Whitepaper')}</FooterLink>
          </FooterItem>
          <FooterItem>{t('Get token')}</FooterItem>
          <FooterItem>
            <FooterLink href={STAKING_URL}>{t('Staking')}</FooterLink>
          </FooterItem>
          <FooterItem>
            <FooterLink href={DOCUMENTATION_URL}>{t('Documentation')}</FooterLink>
          </FooterItem>
        </FooterRow>
        <FooterRow align={'left'}>
          <FooterTitle>{t('About')}</FooterTitle>
          <FooterItem>
            <FooterLink href={ABOUT_URL}>{t('About')}</FooterLink>
          </FooterItem>
          <FooterItem>
            <FooterLink href={BLOG_URL}>{t('Blog')}</FooterLink>
          </FooterItem>
          <FooterItem>
            <FooterLink href={TEAM_URL}>{t('Team')}</FooterLink>
          </FooterItem>
          <FooterItem>
            <FooterLink href={PRIVACY_URL}>{t('Privacy & Terms')}</FooterLink>
          </FooterItem>
        </FooterRow>
        <FooterRow align={'left'}>
          <FooterTitle>{t('Join Newsletter')}</FooterTitle>
          <FooterItem>
            {t('Get the latest Swapp NFT updates')}
            <br />
            <br />
            <FooterLink href={'mailto:' + SUPPORT_MAIL} color={theme.text1}>
              {SUPPORT_MAIL}
            </FooterLink>
          </FooterItem>
          <FooterItem>
            <FooterLink href={FARMING_APPLICATION_URL}>{t('Farming Application')}</FooterLink>
          </FooterItem>
          <FooterItem>
            <FooterLink href={LIST_ON_DEX_URL}>{t('List on CryptoSwapp')}</FooterLink>
          </FooterItem>
        </FooterRow>
      </FooterFrame>
      <FooterBottom>
        <Copyright>Copyright © 2021 Swapp LLC. All rights reserved</Copyright>
        <SocialIcons>
          <FooterLink href={TWITTER_URL}>
            <Icon size={24} image={Twitter} />
          </FooterLink>
          <FooterLink href={TELEGRAM_URL}>
            <Icon size={24} image={Telegram} />
          </FooterLink>
          <FooterLink href={FACEBOOK_URL}>
            <Icon size={24} image={Facebook} />
          </FooterLink>
          <Icon size={24} image={Medium} />
          <Icon size={24} image={Linkedin} />
          <FooterLink href={DISCORD_URL}>
            <Icon size={24} image={Discord} />
          </FooterLink>
          <FooterLink href={INSTAGRAM_URL}>
            <Icon size={24} image={Instagram} />
          </FooterLink>
        </SocialIcons>
      </FooterBottom>
    </FooterContainer>
  )
}

export default withRouter(Footer)
