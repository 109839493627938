import styled from 'styled-components'

export const WrapperAboutUs = styled.div`
  width: 100%;
`
export const FirstBlock = styled.div`
  padding-top: 86px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('./background.png');
  background-size: cover;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100% + 32px);
    position: relative;
    padding: 40px;
    left: -16px;
  `};
`

export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 77px;
  text-align: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
  `};
`
export const TitleDescription = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  margin: 30px 0;
`
export const ButtonsGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`
export const BaseButton = styled.div`
  padding: 10px 0;
  cursor: pointer;
  height: 40px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  justify-content: center;
  border-radius: 53px;
`

export const FirstBlockButton = styled(BaseButton)`
  width: 195px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
      width: 159px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      width: 130px;
  `}
`

export const CustomButtonFill = styled(FirstBlockButton)`
  background-color: ${({ theme }) => theme.secondary1};
`
export const CustomButtonOutline = styled(FirstBlockButton)`
  border: 1px solid #1aa9d6;
  background: transparent;
`

export const ExchangeImgWrapper = styled.div`
  flex: 0 0 100%;
  img {
    margin-top: 80px;
    margin-bottom: 60px;
    max-width: 800px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
     max-width: 600px;
  `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
     max-width: 310px;
  `};
  }
`

export const SecondBlock = styled.div`
  background-color: ${({ theme }) => theme.bg0};
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 40px;
  flex-wrap: wrap;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100% + 32px);
    position: relative;
    padding: 0;
    left: -16px;
  `};
`

export const SItemFirstBase = styled.div`
  border-radius: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 70px 60px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 30px 20px;
    border-radius: 0px;
  `};
`

export const SItemFirst = styled(SItemFirstBase)`
  background-color: ${({ theme }) => theme.bg15};
  ${({ theme }) => theme.mediaWidth.upToMedium`
       flex-direction: column-reverse;
  `};
`

export const SItemSecond = styled(SItemFirstBase)`
  background-color: transparent;
  ${({ theme }) => theme.mediaWidth.upToMedium`
       flex-direction: column;
  `};
`

export const SItemFirstInfo = styled.div`
  width: 461px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`

export const SItemInfoTitle = styled.div<{
  position?: string
}>`
  font-weight: bold;
  font-size: 36px;
  line-height: 56px;
  letter-spacing: -0.02em;
  text-align: ${({ position }) => (position ? position : 'start')};
  padding-bottom: 18px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
       text-align: center;    
       font-size: 24px;   
       line-height: 29px;
  `};
`

export const SItemFirstInfoDescription = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  font-size: 19px;
  line-height: 26px;
  padding-bottom: 18px;
  letter-spacing: 1px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

export const SItemFirstInfoButton = styled(BaseButton)`
  background-color: ${({ theme }) => theme.secondary1};
  width: 461px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`

export const SItemFirstImgWrapper = styled.div`
  img {
    width: 274px;
  }
  display: block;
  ${({ theme }) => theme.mediaWidth.upToSmall`
      img {
    width: 198px;
  }
  `};
`

export const PartnersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex: 0 0 900px;
  gap: 15px;
  margin: 60px 0;
  & svg {
    opacity: 0.3;
    height: 30px;
    width: 150px;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`     
      flex: 0 0 95%;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
      gap: 30px;
      margin: 30px 0;
      svg{
        width: 130px;
      }
  `};
`
