import { TokenItem } from './cryptoSwapp'

export interface LiquidityPairs {
  token0: TokenItem
  token1: TokenItem
  liquidity: number
  volume24h: number
  volume7d: number
  fees24h: number
  fees7d: number
  feesYearly: number
}

export const LiquidityPairsData: LiquidityPairs[] = [
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'Tether USD',
      symbol: 'USDT'
    },
    liquidity: 335300141.56,
    volume24h: 100192882.38,
    volume7d: 459700696.68,
    fees24h: 250482.21,
    fees7d: 1149251.74,
    feesYearly: 27.27
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'CRO',
      symbol: 'CRO'
    },
    liquidity: 255300141.56,
    volume24h: 99192882.38,
    volume7d: 459700696.68,
    fees24h: 211482.21,
    fees7d: 1029251.74,
    feesYearly: 13.27
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'Wrapped CRO',
      symbol: 'WCRO'
    },
    liquidity: 189300151.12,
    volume24h: 146192882.38,
    volume7d: 320700696.68,
    fees24h: 190482.21,
    fees7d: 949251.74,
    feesYearly: -3.27
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'USD//C',
      symbol: 'USDC'
    },
    liquidity: 213300141.56,
    volume24h: 140192882.38,
    volume7d: 500700696.68,
    fees24h: 173482.21,
    fees7d: 1329251.74,
    feesYearly: 11.24
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'Wrapped BTC',
      symbol: 'WBTC'
    },
    liquidity: 185300141.56,
    volume24h: 130192882.38,
    volume7d: 349700696.68,
    fees24h: 220482.21,
    fees7d: 1749251.74,
    feesYearly: 35.24
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'Dai Stablecoin',
      symbol: 'DAI'
    },
    liquidity: 425300141.56,
    volume24h: 210192882.38,
    volume7d: 389700696.11,
    fees24h: 350482.21,
    fees7d: 1649251.74,
    feesYearly: 13.24
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'Wrapped ETH',
      symbol: 'WETH'
    },
    liquidity: 275300141.56,
    volume24h: 90192882.38,
    volume7d: 326700696.68,
    fees24h: 280482.21,
    fees7d: 949251.74,
    feesYearly: 19.12
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'Hibiki.finance',
      symbol: 'cHIBIKI'
    },
    liquidity: 415300141.56,
    volume24h: 220192882.38,
    volume7d: 389700696.68,
    fees24h: 230482.21,
    fees7d: 989251.74,
    feesYearly: 15.35
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'CronosPunkYield',
      symbol: 'CPY'
    },
    liquidity: 275300141.56,
    volume24h: 180192882.38,
    volume7d: 489700696.68,
    fees24h: 290482.21,
    fees7d: 1549251.74,
    feesYearly: 31.01
  },
  {
    token0: {
      name: 'CRO',
      symbol: 'CRO'
    },
    token1: {
      name: 'ETH',
      symbol: 'ETH'
    },
    liquidity: 245300141.56,
    volume24h: 220192882.38,
    volume7d: 539700696.68,
    fees24h: 320482.21,
    fees7d: 1849251.74,
    feesYearly: 36.01
  }
]
