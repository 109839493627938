import React, { Dispatch, SetStateAction, ReactNode, useEffect, useState } from 'react'
import { BarChart, ResponsiveContainer, Tooltip, Bar } from 'recharts'
import styled from 'styled-components'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Card from '../Card'
import { VolumeWindow } from '../../types'
import { RowBetween } from '../Row'
import useTheme from '../../hooks/useTheme'
dayjs.extend(utc)

const DEFAULT_HEIGHT = 200

export const WrapperChart = styled(Card)`
  width: 100%;
  position: relative;
  height: ${DEFAULT_HEIGHT}px;
  padding: 0;
  display: flex;
  background-color: ${({ theme }) => theme.bg15};
  flex-direction: column;
  > * {
    font-size: 1rem;
  }
`

export const LabelAbsoluteRight = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`

export const LabelAbsoluteLeft = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
`

export type LineChartProps = {
  data: any[]
  color?: string | undefined
  height?: number | undefined
  minHeight?: number
  setValue?: Dispatch<SetStateAction<number | undefined>> // used for value on hover
  setLabel?: Dispatch<SetStateAction<string | undefined>> // used for label of valye
  value?: number
  label?: string
  activeWindow?: VolumeWindow
  topLeft?: ReactNode | undefined
  topRight?: ReactNode | undefined
  bottomLeft?: ReactNode | undefined
  bottomRight?: ReactNode | undefined
} & React.HTMLAttributes<HTMLDivElement>

const CustomBar = ({
  x,
  y,
  width,
  height,
  fill
}: {
  x: number
  y: number
  width: number
  height: number
  fill: string
}) => {
  return (
    <g>
      <rect x={x} y={y} fill={fill} width={width} height={height} rx="2" />
    </g>
  )
}

const Chart = ({
  data,
  color = '#56B2A4',
  setValue,
  setLabel,
  value,
  label,
  activeWindow,
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  minHeight = DEFAULT_HEIGHT,
  ...rest
}: LineChartProps) => {
  const theme = useTheme()
  const [now, setNow] = useState<dayjs.Dayjs | null>(null)

  useEffect(() => {
    setNow(dayjs())
  }, [])

  return (
    <WrapperChart minHeight={minHeight} {...rest}>
      <RowBetween height={'160px'}>
        <LabelAbsoluteLeft>{topLeft ?? null}</LabelAbsoluteLeft>
        <LabelAbsoluteRight>{topRight ?? null}</LabelAbsoluteRight>
      </RowBetween>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={200}
          data={data}
          onMouseLeave={() => {
            setLabel && setLabel(undefined)
            setValue && setValue(undefined)
          }}
        >
          <Tooltip
            cursor={{ fill: theme.bg2 }}
            contentStyle={{ display: 'none' }}
            formatter={(value: number, name: string, props: { payload: { time: string; value: number } }) => {
              if (setValue && value !== props.payload.value) {
                setValue(props.payload.value)
              }
              const formattedTime = dayjs(props.payload.time).format('MMM D')
              const formattedTimeDaily = dayjs(props.payload.time).format("MMM D, 'YY")
              const formattedTimePlusWeek = dayjs(props.payload.time).add(1, 'week')
              const formattedTimePlusMonth = dayjs(props.payload.time).add(1, 'month')

              if (setLabel && label !== formattedTime && now) {
                if (activeWindow === VolumeWindow.weekly) {
                  const isCurrent = formattedTimePlusWeek.isAfter(now)
                  setLabel(formattedTime + '-' + (isCurrent ? 'current' : formattedTimePlusWeek.format("MMM D, 'YY")))
                } else if (activeWindow === VolumeWindow.monthly) {
                  const isCurrent = formattedTimePlusMonth.isAfter(now)
                  setLabel(formattedTime + '-' + (isCurrent ? 'current' : formattedTimePlusMonth.format("MMM D, 'YY")))
                } else {
                  setLabel(formattedTimeDaily)
                }
              }
            }}
          />
          <Bar
            dataKey="value"
            fill={color}
            animationDuration={300}
            shape={props => {
              return <CustomBar height={props.height} width={props.width} x={props.x} y={props.y} fill={color} />
            }}
          />
        </BarChart>
      </ResponsiveContainer>
      <RowBetween>
        {bottomLeft ?? null}
        {bottomRight ?? null}
      </RowBetween>
    </WrapperChart>
  )
}

export default Chart
